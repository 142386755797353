import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router'
import { Title } from '@angular/platform-browser';

declare var gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    constructor(
        private router: Router,
        private titleService: Title
        ){ }

    ngOnInit() {
        // this.router.events.subscribe(event => {
        //     if (event instanceof NavigationEnd) {
        //         (<any>window).gtag('config', 'UA-119755911-3', {
        //             // 'page_title' : this.titleService.getTitle(),
        //             'page_path': event.urlAfterRedirects
        //         });
        //     }
        // });

        // if(JSON.parse(localStorage.getItem('user')))
            // this.router.navigate(['/orders']);


    }

}
