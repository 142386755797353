import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } 					from  '../../api.service';
import { Router} 						from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bulk-status',
  templateUrl: './bulk-status.component.html',
  styleUrls: ['./bulk-status.component.css']
})
export class BulkStatusComponent implements OnInit {

	form: FormGroup;
  reshipForm: FormGroup;
	orders: Array<Object> = [];
	failedOrders: Array<String> = [];
	submitted = false;
	successCount = 0;
  tabtype = 'success';

	element: HTMLElement;

  constructor(
  	private fb: FormBuilder,
  	private apiService: ApiService) { }

  get f() { return this.form.controls; }
  get rf() { return this.reshipForm.controls; }

  ngOnInit() {

  	this.form = this.fb.group({
        trackingNumber: 		['', [Validators.required]],
        status: 				    ['', [Validators.required]],
        reasonCode: 			  ['', []],
        tplReasonCode: 			['', [Validators.required]],
        driverContact: 			['601153097500', [Validators.required]],
        driverName: 			  ['Admin', [Validators.required]],
        driverImage: 			  ['-', [Validators.required]],
        receiverName:       ['-', []],
        receiverSignature:  ['-', []]
    });

    this.reshipForm = this.fb.group({
        trackingNumber:     ['', [Validators.required]],
        status:             ['', [Validators.required]],
        // reasonCode:         ['', [Validators.required]],
        tplReasonCode:       ['', [Validators.required]],
    });
  }

  startBulk() {

  		this.successCount = 0;
      
      let values =  (<HTMLInputElement>document.getElementById("tnModel")).value.split("\n");


      if(values.length > 0) {
        // let orders: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            this.orders.push({'trackingNumber': values[i]});
        }

      		this.bulkUpdate(0);
      }
    }

    async bulkUpdate(index) {

    	this.submitted = true

      if(this.tabtype !== 'reship') {

        if(this.orders[index])
          this.form.controls['trackingNumber'].setValue(this.orders[index]['trackingNumber']);

        console.log(this.form.getRawValue())
        if(this.form.valid) {
          this.apiService.adminBulkFailed(this.form.getRawValue()).subscribe(data => {

                if (data.status) {
                  this.successCount++;

                  if((index+1) < this.orders.length) {
                        this.bulkUpdate(index+1);  
                  }
                  else {
                    this.resetOnHoldForm();
                    alert('Completed updates for all listed TNs.');
                  }
                }  
                else {
                  this.failedOrders.push(this.orders[index]['trackingNumber']+' | '+data.message);
                  this.bulkUpdate(index+1);
                }
              });
          this.submitted = false;
        }
        else
          alert('Please recheck all form inputs')  
      }
      else if(this.tabtype === 'reship') {

        if(this.orders[index])
          this.reshipForm.controls['trackingNumber'].setValue(this.orders[index]['trackingNumber']);

        if(this.reshipForm.valid) {
          this.apiService.adminBulkReship(this.reshipForm.getRawValue()).subscribe(data => {

                if (data.status) {
                  this.successCount++;

                  if((index+1) < this.orders.length) {
                        this.bulkUpdate(index+1);  
                  }
                  else {
                    this.resetOnHoldForm();
                  }
                }  
                else {
                  this.failedOrders.push(this.orders[index]['trackingNumber']+' | '+data.message);
                  this.bulkUpdate(index+1);
                }
              });
          this.submitted = false;
        }  
      }
      
    }

    resetOnHoldForm() {
        (<HTMLInputElement>document.getElementById("tnModel")).value = '';
        
        this.orders = [];
      }

  clearFailed() {

      this.failedOrders = [];
      this.resetOnHoldForm();
      this.successCount = 0;
  }

}
