import { Injectable } 						from '@angular/core';
import { HttpClient, HttpHeaders } 			from '@angular/common/http';
import { Observable, of } 					from 'rxjs';
import { catchError, map, tap } 			from 'rxjs/operators';
import { Router} 							from '@angular/router';


const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
	// headers: new HttpHeaders().set('A-Auth-Token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJsdW1lbi1qd3QiLCJzdWIiOjEsImlhdCI6MTUyOTM0NDA4NywiZXhwIjoxNTI5NDMwNDg3fQ.b8X2DVGMaRoFVj3plYaAQjia7Qh54J-PnNuoi-Ub3Eg'),
};

@Injectable({
	providedIn: 'root'
})

export class ApiService {

private BASE_URL  						=  'https://api.urbandespatch.com';
// private BASE_URL  						=  'https://api-dev.urbandespatch.com';
// private BASE_URL  						=  'http://localhost:8000';

private LOGIN_URL						=  (this.BASE_URL + '/login');
private ACTIVATE_URL					=  (this.BASE_URL + '/user/activate');
private RESETPASSWORD_URL				=  (this.BASE_URL + '/user/resetPassword');
private COMPLETERESETPASSWORD_URL		=  (this.BASE_URL + '/user/completeResetPassword');

private COMPANYOPTIONS_URL				=  (this.BASE_URL + '/supply/company/options');
private STAFFOPTIONS_URL				=  (this.BASE_URL + '/supply/staff/options');
private TRANSPORTOPTIONS_URL			=  (this.BASE_URL + '/supply/transport/options');
private CLIENTOPTIONS_URL				=  (this.BASE_URL + '/supply/client/options');

private COMPANYCREATE_URL				=  (this.BASE_URL + '/supply/company');
private STAFFCREATE_URL					=  (this.BASE_URL + '/supply/staff');
private TRANSPORTCREATE_URL				=  (this.BASE_URL + '/supply/transport');
private CLIENTCREATE_URL				=  (this.BASE_URL + '/supply/client');

private COMPANYSEARCH_URL				=  (this.BASE_URL + '/supply/company/search');
private STAFFSEARCH_URL					=  (this.BASE_URL + '/supply/staff/search');
private TRANSPORTSEARCH_URL				=  (this.BASE_URL + '/supply/transport/search');
private CLIENTSEARCH_URL				=  (this.BASE_URL + '/supply/client/search');

private COMPANYTOGGLEACTIVE_URL			=  (this.BASE_URL + '/supply/company/toggleActive/');
private COMPANYUPDATE_URL				=  (this.BASE_URL + '/supply/company/update');
private COMPANYEXPORT_URL				=  (this.BASE_URL + '/supply/company/export');

private STAFFTOGGLEACTIVE_URL			=  (this.BASE_URL + '/supply/staff/toggleActive/');
private STAFFUPDATE_URL					=  (this.BASE_URL + '/supply/staff/update');
private STAFFEXPORT_URL					=  (this.BASE_URL + '/supply/staff/export');
private STAFFRESETPASSWORD_URL			=  (this.BASE_URL + '/supply/staff/resetPassword');

private TRANSPORTUPDATE_URL					=  (this.BASE_URL + '/supply/transport/update');
private TRANSPORTEXPORT_URL					=  (this.BASE_URL + '/supply/transport/export');

private CLIENTTOGGLEACTIVE_URL			=  (this.BASE_URL + '/supply/client/toggleActive/');
private CLIENTUPDATE_URL				=  (this.BASE_URL + '/supply/client/update');
private CLIENTEXPORT_URL				=  (this.BASE_URL + '/supply/client/export');

private REGISTER_URL					=  (this.BASE_URL + '/user/register');
private REGISTEROPTIONS_URL				=  (this.BASE_URL + '/user/register/options');

private USERCHANGEPASSWORD_URL			=  (this.BASE_URL + '/user/changePassword');
private SYSTEMREPORTISSUE_URL			=  (this.BASE_URL + '/system/issue');

private USERPROFILEOPTIONS_URL			=  (this.BASE_URL + '/user/profile/options');
private USERPROFILE_URL					=  (this.BASE_URL + '/user/profile');
private USERPROFILEIMAGE_URL			=  (this.BASE_URL + '/user/profile/image');
private USERPROFILEUPDATE_URL			=  (this.BASE_URL + '/user/profile');



// LAZADA ROUTES
private LAZADAPACKAGESEARCHOPTIONS_URL	=  (this.BASE_URL + '/lel/packages/search/options');
private LAZADAPACKAGESEARCH_URL			=  (this.BASE_URL + '/lel/packages/search');
private LAZADAPACKAGEINBOUNDOPTIONS_URL	=  (this.BASE_URL + '/lel/packages/inboundOptions');
private LAZADAPACKAGEDETAILS_URL		=  (this.BASE_URL + '/lel/packages/');
private LAZADAPACKAGETIMELINESLUG_URL	=  ('/timeline');
private LAZADATRACKINGDETAILS_URL		=  (this.BASE_URL + '/lazada');

private LAZADAPACKAGESCANOPTIONS_URL	=  (this.BASE_URL + '/lel/packages/scan/options');
private LAZADAPACKAGESCAN_URL			=  (this.BASE_URL + '/lel/packages/scan');
private LAZADAPACKAGECOMMENT_URL		=  (this.BASE_URL + '/lel/packages/comment');
private LAZADAPACKAGECOMMENTBREACH_URL	=  (this.BASE_URL + '/lel/packages/commentBreach');

private LAZADAPACKAGEUPDATEWEIGHT_URL	=  (this.BASE_URL + '/lel/packages/weight');

private LAZADAPACKAGEINTRANSIT_URL		=  (this.BASE_URL + '/lel/packages/intransit');

private LAZADAORDERSREPORTINDEX			=  (this.BASE_URL + '/lel/reports/orders/index');
private LAZADAORDERSREPORT				=  (this.BASE_URL + '/lel/reports/orders');
private LAZADAORDERSREPORTBULK			=  (this.BASE_URL + '/lel/reports/ordersBulk');
private LAZADASTATUSREPORT				=  (this.BASE_URL + '/lel/reports/status');


private PACKAGESIMPORT_URL							=  (this.BASE_URL + '/3pl/order');
private PACKAGESIMPORTOPTIONS_URL					=  (this.BASE_URL + '/3pl/order/options');

private PACKAGESSHUNTCUSTOMEROPTIONS_URL			=  (this.BASE_URL + '/packages/shunt/customer/options');
private PACKAGESSHUNTCUSTOMERINCOMINGOPTIONS_URL	=  (this.BASE_URL + '/packages/shunt/customer/incoming/options');
private PACKAGESSHUNTCUSTOMERTOTAL_URL		=  (this.BASE_URL + '/packages/shunt/customer/total');
private PACKAGESSHUNTCUSTOMERTOTALUDSC_URL	=  (this.BASE_URL + '/packages/shunt/customer/totalUDSC');
private PACKAGESSHUNT_URL					=  (this.BASE_URL + '/packages/shunt');
private PACKAGESSHUNTSUMMARY_URL			=  (this.BASE_URL + '/packages/shunt/summary');
private PACKAGESSHUNOPTIONS_URL				=  (this.BASE_URL + '/packages/shunt/options');
private PACKAGESSHUNTMANIFEST_URL			=  (this.BASE_URL + '/packages/shunt/manifest');

private PACKAGESHUBOPTIONS_URL				=  (this.BASE_URL + '/packages/hub/options');
private PACKAGESHUBCREATE_URL				=  (this.BASE_URL + '/packages/hub');
private PACKAGESHUBSGET_URL					=  (this.BASE_URL + '/packages/hubs');
private PACKAGESHUBTOGGLEACTIVE_URL			=  (this.BASE_URL + '/packages/hub/toggleActive/');
private PACKAGESHUBUPDATE_URL				=  (this.BASE_URL + '/packages/hub/update');
private PACKAGESHUBUSERACTIVATE_URL			=  (this.BASE_URL + '/packages/hub/user/activate/');
private PACKAGESHUBUSERTOGGLEBAN_URL		=  (this.BASE_URL + '/packages/hub/user/toggleBan/');
private PACKAGESHUBUSERUPDATEROLE_URL		=  (this.BASE_URL + '/packages/hub/user/updateRole');

private PACKAGESSHUNTCUSTOMERDOWNLOAD_URL	=  (this.BASE_URL + '/packages/shunt/customer');
private PACKAGESSHUNTINCOMINGDOWNLOAD_URL	=  (this.BASE_URL + '/packages/shunt/incoming');

private PACKAGESPICKUPRESCUE3PLOPTIONS_URL	=  (this.BASE_URL + '/packages/pickup/other3pl/options');
private PACKAGESPICKUPRESCUE3PL_URL			=  (this.BASE_URL + '/packages/pickup/other3pl');
private PACKAGESPICKUPRESCUE3PLORDERS_URL	=  (this.BASE_URL + '/packages/pickup/other3pl/');
private PACKAGESPICKUPRESCUE3PLREMOVE_URL	=  (this.BASE_URL + '/packages/pickup/other3pl/');

private PACKAGESPICKUPCUSTOMEROPTIONS_URL	=  (this.BASE_URL + '/packages/pickup/customer/options');
private PACKAGESPICKUPCUSTOMER_URL			=  (this.BASE_URL + '/packages/pickup/customer');
private PACKAGESPICKUPCUSTOMERORDERS_URL	=  (this.BASE_URL + '/packages/pickup/customer/');
private PACKAGESPICKUPCUSTOMERREMOVE_URL	=  (this.BASE_URL + '/packages/pickup/customer/');
private PACKAGESPICKUPCUSTOMERCONFIRMIMPORTED_URL	=  (this.BASE_URL + '/packages/pickup/customer/confirmImported');
private PACKAGESPICKUPCUSTOMERCONFIRMRECEIVED_URL	=  (this.BASE_URL + '/packages/pickup/customer/confirmReceived');
private PACKAGESPICKUPCUSTOMERCONFIRMREATTEMPT_URL	=  (this.BASE_URL + '/packages/pickup/customer/confirmReattempt');
private PACKAGESPICKUPMANUALCONFIRM_URL				=  (this.BASE_URL + '/packages/pickup/manualConfirm/');
private PACKAGESPICKUPREVOKEDRIVER_URL				=  (this.BASE_URL + '/packages/pickup/revokeDriver/');
private PACKAGESPICKUPRESENDEMAIL_URL				=  (this.BASE_URL + '/packages/pickup/resendEmail/');
private PACKAGESPICKUPSCRAPORDER_URL				=  (this.BASE_URL + '/packages/pickup/scrapOrder/');
private PACKAGESPICKUPREOPENORDER_URL				=  (this.BASE_URL + '/packages/pickup/reopen/');
private PACKAGESPICKUPGETORF_URL					=  (this.BASE_URL + '/packages/pickup/getOrf/');
private PACKAGESPICKUPCANCELTRIP_URL				=  (this.BASE_URL + '/packages/pickup/cancelTrip/');

private PACKAGESPICKUPCUSTOMERDOWNLOAD_URL	=  (this.BASE_URL + '/packages/pickup/customer/');

private PACKAGESPICKUPASSIGNINDEX_URL		=  (this.BASE_URL + '/packages/pickup/assign/index');
private PACKAGESPICKUPASSIGNDRIVERTRIPS_URL	=  (this.BASE_URL + '/packages/pickup/assign/trips/');
private PACKAGESPICKUPASSIGNORDER_URL		=  (this.BASE_URL + '/packages/pickup/assign/driver');
private PACKAGESPICKUPASSIGNREVOKE_URL		=  (this.BASE_URL + '/packages/pickup/assign/revoke/');

private PACKAGESUPDATEWAREHOUSELOST_URL		=  (this.BASE_URL + '/packages/lost/warehouse/');
private PACKAGESUPDATEDRIVERLOST_URL		=  (this.BASE_URL + '/packages/lost/driver/');

private PACKAGESUPDATEWAREHOUSEBREACH_URL	=  (this.BASE_URL + '/packages/breach/warehouse/');
private PACKAGESUPDATEDRIVERBREACH_URL		=  (this.BASE_URL + '/packages/breach/driver/');

private PACKAGESPICKUPENDREATTEMPTED_URL	=  (this.BASE_URL + '/packages/pickup/customer/endReattempt');

private PACKAGESINBOUND_URL					=  (this.BASE_URL + '/packages/inbound');
	
private PACKAGESOUTBOUNDDELIVERYOPTIONS_URL	=  (this.BASE_URL + '/packages/outbound/delivery/options');	
private PACKAGESOUTBOUNDDELIVERY_URL		=  (this.BASE_URL + '/packages/outbound/delivery');
private PACKAGESOUTBOUNDDELIVERYORDERS_URL	=  (this.BASE_URL + '/packages/outbound/delivery/');
private PACKAGESOUTBOUNDDELIVERYREMOVE_URL	=  (this.BASE_URL + '/packages/outbound/delivery/');

private PACKAGESOUTBOUNDTRANSFER_URL		=  (this.BASE_URL + '/packages/outbound/transfer');
private PACKAGESOUTBOUNDTRANSFERORDERS_URL	=  (this.BASE_URL + '/packages/outbound/transfer/');
private PACKAGESOUTBOUNDTRANSFERREMOVE_URL	=  (this.BASE_URL + '/packages/outbound/transfer/');

private PACKAGESOUTBOUNDRETURNOPTIONS_URL	=  (this.BASE_URL + '/packages/outbound/return/options');
private PACKAGESOUTBOUNDRETURN_URL			=  (this.BASE_URL + '/packages/outbound/return');
private PACKAGESOUTBOUNDRETURNORDERS_URL	=  (this.BASE_URL + '/packages/outbound/return/');
private PACKAGESOUTBOUNDRETURNREMOVE_URL	=  (this.BASE_URL + '/packages/outbound/return/');

private PACKAGESOUTBOUNDWHCONFIRM_URL		=  (this.BASE_URL + '/packages/outbound/warehouse/confirm');
private PACKAGESOUTBOUNDONHOLD_URL			=  (this.BASE_URL + '/packages/outbound/return/onHold');
private PACKAGESOUTBOUNDRTS_URL				=  (this.BASE_URL + '/packages/outbound/return/rts');

private PICKUPRSVPDETAILS_URL				=  (this.BASE_URL + '/rsvp/customer');

private SHUNTDASHBOARDUD_URL				=  (this.BASE_URL + '/dashboard/shunt/UD');


private ADMINBULKSTATUSFAILED_URL			=  (this.BASE_URL + '/admin/bulk/failed');
private ADMINBULKSTATUSRESHIP_URL			=  (this.BASE_URL + '/admin/bulk/reship');


private GETMOBILEUNIT_URL				=  (this.BASE_URL + '/mobileUnits/');
private SAVEMOBILEUNIT_URL				=  (this.BASE_URL + '/mobileUnits');
private ADDINTOMU_URL					=  (this.BASE_URL + '/mobileUnits/add');
private REMOVEFROMMU_URL				=  (this.BASE_URL + '/mobileUnits/remove');
private TRANSFERMU_URL					=  (this.BASE_URL + '/mobileUnits/transfer');
private REPORTMU_URL					=  (this.BASE_URL + '/mobileUnits/get/report');




// BUSINESS JOB ROUTES
private BUSINESSJOBOPTIONS_URL				=  (this.BASE_URL + '/business/job/options');
private BUSINESSJOBCREATE_URL				=  (this.BASE_URL + '/business/job/create');
private BUSINESSJOBS_URL					=  (this.BASE_URL + '/business/jobs');
private BUSINESSJOBSGET_URL					=  (this.BASE_URL + '/business/jobs/');
private BUSINESSJOBUPDATE_URL				=  (this.BASE_URL + '/business/job/update');
private BUSINESSJOBPAYMENT_URL				=  (this.BASE_URL + '/business/job/payment');
private BUSINESSJOBCOMMENT_URL				=  (this.BASE_URL + '/business/job/comment');
private BUSINESSJOBSTATUS_URL				=  (this.BASE_URL + '/business/job/status');
private BUSINESSJOBDOCUMENTUPLOAD_URL		=  (this.BASE_URL + '/business/job/documentUpload');
private BUSINESSJOBDOCUMENTDELETE_URL		=  (this.BASE_URL + '/business/job/document/');



constructor(private http:  HttpClient,private router: Router) { }

	
	headers(): HttpHeaders {
		return new HttpHeaders()
			.set('A-Auth-Token', this.getToken())
	        .append('Content-Type', 'application/json');
	}

	fileHeaders(): HttpHeaders {
		return new HttpHeaders()
			.set('A-Auth-Token', this.getToken())
	        .append('Accept', 'video/*')
	        .append('Accept', 'application/pdf');

	}

	uploadHeaders(): HttpHeaders {
		return new HttpHeaders()
			.set('A-Auth-Token', this.getToken())
	        .append('enctype', 'multipart/form-data; boundary=' + Math.random().toString().substr(2))
	        .append('Accept', '*/*')

	}

	getToken(): string {
		if (localStorage.getItem('user') !== null) {
			const json = JSON.parse(localStorage.getItem('user'));

			if (!json.token) {
				return '';
			}

			return json.token;
		}
		return null;
	}

	// AUTHETICATION
	

	login(param: any): Observable<any> {
	  return this.http.post(this.LOGIN_URL, param)
	  	.pipe(
	    	catchError(this.handleError<JSON>('login'))
	  	);
	}

	activate(code: any): Observable<any> {
	  return this.http.get(this.ACTIVATE_URL + '?code=' + code)
	  	.pipe(
	    	catchError(this.handleError<JSON>('activate'))
	  	);
	}

	resetPassword(param: any): Observable<any> {
	  return this.http.post(this.RESETPASSWORD_URL, param)
	  	.pipe(
	    	catchError(this.handleError<JSON>('resetPassword'))
	  	);
	}

	resetPasswordComplete(code: any, email: any): Observable<any> {
	  return this.http.get(this.COMPLETERESETPASSWORD_URL + '?code=' + code + '&email=' + email)
	  	.pipe(
	    	catchError(this.handleError<JSON>('resetPasswordComplete'))
	  	);
	}


	// MANAGE SUPPLY
	companyOptions(): Observable<any> {
	  return this.http.get(this.COMPANYOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('companyOptions'))
	  	);
	}

	staffOptions(): Observable<any> {
	  return this.http.get(this.STAFFOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('staffOptions'))
	  	);
	}

	transportOptions(): Observable<any> {
	  return this.http.get(this.TRANSPORTOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('transportOptions'))
	  	);
	}

	clientOptions(): Observable<any> {
	  return this.http.get(this.CLIENTOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('clientOptions'))
	  	);
	}

	// CREATE SUPPLY
	companyCreate(param: any): Observable<any> {
	  return this.http.post(this.COMPANYCREATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('companyCreate'))
  		);
	}

	staffCreate(param: any): Observable<any> {
	  return this.http.post(this.STAFFCREATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('staffCreate'))
  		);
	}

	transportCreate(param: any): Observable<any> {
	  return this.http.post(this.TRANSPORTCREATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('transportCreate'))
  		);
	}

	clientCreate(param: any): Observable<any> {
	  return this.http.post(this.CLIENTCREATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('clientCreate'))
  		);
	}

	// SEARCH SUPPLY
	companySearch(param: any): Observable<any> {
	  return this.http.post(this.COMPANYSEARCH_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('companySearch'))
  		);
	}

	companyToggleActive(id): Observable<any> {
		return this.http.get(this.COMPANYTOGGLEACTIVE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubOptions'))
  		);
	}

	companyUpdate(param: any): Observable<any> {
		return this.http.post(this.COMPANYUPDATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubCreate'))
  		);
	}

	companyExport(): Observable<any> {
		return this.http.get(this.COMPANYEXPORT_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubOptions'))
  		);
	}

	staffSearch(param: any): Observable<any> {
	  return this.http.post(this.STAFFSEARCH_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('staffSearch'))
  		);
	}

	staffToggleActive(id): Observable<any> {
		return this.http.get(this.STAFFTOGGLEACTIVE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubOptions'))
  		);
	}

	staffUpdate(param: any): Observable<any> {
		return this.http.post(this.STAFFUPDATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubCreate'))
  		);
	}

	staffExport(): Observable<any> {
		return this.http.get(this.STAFFEXPORT_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubOptions'))
  		);
	}

	staffResetPassword(param: any): Observable<any> {
		return this.http.post(this.STAFFRESETPASSWORD_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubCreate'))
  		);
	}

	transportSearch(param: any): Observable<any> {
	  return this.http.post(this.TRANSPORTSEARCH_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('transportSearch'))
  		);
	}


	transportUpdate(param: any): Observable<any> {
		return this.http.post(this.TRANSPORTUPDATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubCreate'))
  		);
	}

	transportExport(): Observable<any> {
		return this.http.get(this.TRANSPORTEXPORT_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubOptions'))
  		);
	}

	clientSearch(param: any): Observable<any> {
	  return this.http.post(this.CLIENTSEARCH_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('transportSearch'))
  		);
	}

	clientUpdate(param: any): Observable<any> {
		return this.http.post(this.CLIENTUPDATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubCreate'))
  		);
	}

	clientExport(): Observable<any> {
		return this.http.get(this.CLIENTEXPORT_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('clientExport'))
  		);
	}



	// MANAGE USERS
	registerOptions(): Observable<any> {
	  return this.http.get(this.REGISTEROPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('REGISTEROPTIONS_URL'))
	  	);
	}

	register(param: any): Observable<any> {
	  return this.http.post(this.REGISTER_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('register'))
  		);
	}


	// PROFILE
	userProfileOptions(): Observable<any> {
	  return this.http.get(this.USERPROFILEOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	userProfile(): Observable<any> {
	  return this.http.get(this.USERPROFILE_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	userProfileUploadImage(param: FormData) {

		return this.http.post(this.USERPROFILEIMAGE_URL, param, { headers: this.fileHeaders() })
		.pipe(
	    	catchError(this.handleError<JSON>('aboutmeimage'))
	  	);
  	}

	userProfileImage() {

		return this.http.get(this.USERPROFILEIMAGE_URL, { headers: this.headers(), responseType: 'blob' });
  	}

	userProfileUpdate(param: any): Observable<any> {
	  return this.http.patch(this.USERPROFILEUPDATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	
	// ACCOUNT SETTINGS
	userChangePassword(param: any): Observable<any> {
		return this.http.post(this.USERCHANGEPASSWORD_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('userChangePassword'))
  		);
	}

	systemReportIssue(param: any): Observable<any> {
		return this.http.post(this.SYSTEMREPORTISSUE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('systemReportIssue'))
  		);
	}




	// ORDERS
	importOptions(): Observable<any> {
	  return this.http.get(this.PACKAGESIMPORTOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('REGISTEROPTIONS_URL'))
	  	);
	}

	importOrder(param: any): Observable<any> {
		return this.http.post(this.PACKAGESIMPORT_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('PACKAGESIMPORT_URL'))
  		);
	}

	searchOrder(param: any): Observable<any> {
		return this.http.post(this.LAZADAPACKAGESEARCH_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('searchOrder'))
  		);
	}

	inboundOptions(): Observable<any> {
		return this.http.get(this.LAZADAPACKAGEINBOUNDOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('inboundOptions'))
  		);
	}

	updateOrderWeight(param: any): Observable<any> {
		return this.http.patch(this.LAZADAPACKAGEUPDATEWEIGHT_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('updateOrderWeight'))
  		);
	}

	customerForwardTotalStats(): Observable<any> {
	  return this.http.get(this.PACKAGESSHUNTCUSTOMERTOTAL_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESSHUNTCUSTOMERTOTAL_URL'))
	  	);
	}

	customerForwardTotalUDSCStats(): Observable<any> {
	  return this.http.get(this.PACKAGESSHUNTCUSTOMERTOTALUDSC_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESSHUNTCUSTOMERTOTALUDSC_URL'))
	  	);
	}

	customerForwardOptions(id, hubId): Observable<any> {

		var route = '';

		if(hubId != '' )
			route = '/' + id + '/' + hubId;
		else 
			route = '/' + id;

	  return this.http.get(this.PACKAGESSHUNTCUSTOMEROPTIONS_URL + route, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESSHUNTCUSTOMEROPTIONS_URL'))
	  	);
	}

	
	customerForwardInTsntOptions(id, hubId): Observable<any> {

		var route = '';

		if(hubId != '' )
			route = '/' + id + '/' + hubId;
		else 
			route = '/' + id;

	  return this.http.get(this.PACKAGESSHUNTCUSTOMERINCOMINGOPTIONS_URL + route, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESSHUNTCUSTOMERINCOMINGOPTIONS_URL'))
	  	);
	}

	shuntOptions(): Observable<any> {
		return this.http.get(this.PACKAGESSHUNOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('shuntOptions'))
  		);
	}

	shunt(param: any): Observable<any> {
		return this.http.post(this.PACKAGESSHUNT_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('searchOrder'))
  		);
	}

	shuntSummary(param: any): Observable<any> {
		return this.http.post(this.PACKAGESSHUNTSUMMARY_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('shuntSummary'))
  		);
	}

	shuntManifest(param: any): Observable<any> {
		return this.http.post(this.PACKAGESSHUNTMANIFEST_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('PACKAGESSHUNTMANIFEST_URL'))
  		);
	}


	rescue3plOptions(): Observable<any> {
	  return this.http.get(this.PACKAGESPICKUPRESCUE3PLOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESOUTBOUNDRETURNOPTIONS_URL'))
	  	);
	}

	rescue3pl(param: any): Observable<any> {
		return this.http.post(this.PACKAGESPICKUPRESCUE3PL_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('PACKAGESOUTBOUNDTRANSFER_URL'))
  		);
	}
	
	rescue3plOrders(id: any): Observable<any> {
	  return this.http.get(this.PACKAGESPICKUPRESCUE3PLORDERS_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESOUTBOUNDTRANSFERORDERS_URL'))
	  	);
	}

	rescue3plRemove(id: any): Observable<any> {
	  return this.http.delete(this.PACKAGESPICKUPRESCUE3PLREMOVE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESOUTBOUNDTRANSFERREMOVE_URL'))
	  	);
	}


	customerReturnOptions($id): Observable<any> {
	  return this.http.get(this.PACKAGESPICKUPCUSTOMEROPTIONS_URL + '/' + $id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESPICKUPCUSTOMEROPTIONS_URL'))
	  	);
	}

	customerReturnDownload(type): Observable<any>  {
		return this.http.get(this.PACKAGESPICKUPCUSTOMERDOWNLOAD_URL + type, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESPICKUPCUSTOMERDOWNLOAD_URL'))
	  	);

	}

	customerForwardDownload(type, hubId): Observable<any>  {

		var route = '';

		if(hubId != '' )
			route = '/' + type + '/' + hubId;
		else 
			route = '/' + type;

		return this.http.get(this.PACKAGESSHUNTCUSTOMERDOWNLOAD_URL + route, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESPICKUPCUSTOMERDOWNLOAD_URL'))
	  	);

	}

	customerInstransitDownload(hubId): Observable<any>  {

		var route = '';

		if(hubId != '' )
			route = '/'  + hubId;
		// else 
			// route = '/';

		return this.http.get(this.PACKAGESSHUNTINCOMINGDOWNLOAD_URL + route, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESPICKUPCUSTOMERDOWNLOAD_URL'))
	  	);

	}

	// customerReturn(param: any): Observable<any> {
	// 	return this.http.post(this.PACKAGESPICKUPCUSTOMER_URL, param, { headers: this.headers() })
	//   	.pipe(
	//     	catchError(this.handleError<any>('PACKAGESOUTBOUNDTRANSFER_URL'))
 //  		);
	// }
	
	// customerReturnOrders(id: any): Observable<any> {
	//   return this.http.get(this.PACKAGESPICKUPCUSTOMERORDERS_URL + id, { headers: this.headers() })
	//   	.pipe(
	//     	catchError(this.handleError<JSON>('PACKAGESOUTBOUNDTRANSFERORDERS_URL'))
	//   	);
	// }

	// customerReturnRemove(id: any): Observable<any> {
	//   return this.http.delete(this.PACKAGESPICKUPCUSTOMERREMOVE_URL + id, { headers: this.headers() })
	//   	.pipe(
	//     	catchError(this.handleError<JSON>('PACKAGESOUTBOUNDTRANSFERREMOVE_URL'))
	//   	);
	// }


	customerReturnConfirmImported(): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPCUSTOMERCONFIRMIMPORTED_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('searchOrder'))
  		);
	}

	customerReturnConfirmReceived(): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPCUSTOMERCONFIRMRECEIVED_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('searchOrder'))
  		);
	}

	customerReturnConfirmReattempt(): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPCUSTOMERCONFIRMREATTEMPT_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('searchOrder'))
  		);
	}

	customerReturnAssignIndex(): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPASSIGNINDEX_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('customerReturnAssignIndex'))
  		);
	}

	customerReturnDriverTrips(id): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPASSIGNDRIVERTRIPS_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('customerReturnAssignIndex'))
  		);
	}

	customerReturnAssignOrder(param): Observable<any> {
		return this.http.post(this.PACKAGESPICKUPASSIGNORDER_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('customerReturnAssignIndex'))
  		);
	}

	customerReturnAssignRevoke(deliveryId): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPASSIGNREVOKE_URL + deliveryId, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('customerReturnAssignIndex'))
  		);
	}

	customerReturnEndReattempted(): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPENDREATTEMPTED_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('customerReturnAssignIndex'))
  		);
	}

	customerReturnManualConfirm(code): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPMANUALCONFIRM_URL + code, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('customerReturnManualConfirm'))
  		);
	}

	customerReturnRevokeDriver(code): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPREVOKEDRIVER_URL + code, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('customerReturnRevokeDriver'))
  		);
	}

	customerReturnResendEmail(code): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPRESENDEMAIL_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('customerReturnResendEmail'))
  		);
	}

	customerReturnScrapOrder(code): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPSCRAPORDER_URL + code, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('customerReturnScrapOrder'))
  		);
	}


	packageWarehouseLost(code): Observable<any> {
		return this.http.get(this.PACKAGESUPDATEWAREHOUSELOST_URL + code, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('packageWarehouseLost'))
  		);
	} 

	packageDriverLost(code): Observable<any> {
		return this.http.get(this.PACKAGESUPDATEDRIVERLOST_URL + code, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('packageDriverLost'))
  		);
	} 


	packageWarehouseBreach(code): Observable<any> {
		return this.http.get(this.PACKAGESUPDATEWAREHOUSEBREACH_URL + code, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('packageWarehouseBreach'))
  		);
	} 

	packageDriverBreach(code): Observable<any> {
		return this.http.get(this.PACKAGESUPDATEDRIVERBREACH_URL + code, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('packageDriverBreach'))
  		);
	} 


	customerReturnReopenOrder(code): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPREOPENORDER_URL + code, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('customerReturnReopenOrder'))
  		);
	} 


	customerReturnPrintOrf(code): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPGETORF_URL + code, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('customerReturnReopenOrder'))
  		);
	} 

	customerReturnCancelTrip(id): Observable<any> {
		return this.http.get(this.PACKAGESPICKUPCANCELTRIP_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('customerReturnCancelTrip'))
  		);
	} 




	inbound(param: any): Observable<any> {
		return this.http.post(this.PACKAGESINBOUND_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('searchOrder'))
  		);
	}

	outboundDelivery(param: any): Observable<any> {
		return this.http.post(this.PACKAGESOUTBOUNDDELIVERY_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('PACKAGESOUTBOUNDDELIVERY_URL'))
  		);
	}
	
	outboundDeliveryOrders(id: any): Observable<any> {
	  return this.http.get(this.PACKAGESOUTBOUNDDELIVERYORDERS_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESOUTBOUNDDELIVERYORDERS_URL'))
	  	);
	}

	outboundDeliveryRemove(id: any): Observable<any> {
	  return this.http.delete(this.PACKAGESOUTBOUNDDELIVERYREMOVE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESOUTBOUNDREMOVEORDER_URL'))
	  	);
	}

	outboundDeliveryOptions(): Observable<any> {
	  return this.http.get(this.PACKAGESOUTBOUNDDELIVERYOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESOUTBOUNDRETURNOPTIONS_URL'))
	  	);
	}



	outboundTransfer(param: any): Observable<any> {
		return this.http.post(this.PACKAGESOUTBOUNDTRANSFER_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('PACKAGESOUTBOUNDTRANSFER_URL'))
  		);
	}
	
	outboundTransferOrders(id: any): Observable<any> {
	  return this.http.get(this.PACKAGESOUTBOUNDTRANSFERORDERS_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESOUTBOUNDTRANSFERORDERS_URL'))
	  	);
	}

	outboundTransferRemove(id: any): Observable<any> {
	  return this.http.delete(this.PACKAGESOUTBOUNDTRANSFERREMOVE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESOUTBOUNDTRANSFERREMOVE_URL'))
	  	);
	}


	outboundReturnOptions(): Observable<any> {
	  return this.http.get(this.PACKAGESOUTBOUNDRETURNOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESOUTBOUNDRETURNOPTIONS_URL'))
	  	);
	}

	outboundReturn(param: any): Observable<any> {
		return this.http.post(this.PACKAGESOUTBOUNDRETURN_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('PACKAGESOUTBOUNDRETURN_URL'))
  		);
	}

	outboundReturnOrders(id: any): Observable<any> {
	  return this.http.get(this.PACKAGESOUTBOUNDRETURNORDERS_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESOUTBOUNDRETURNORDERS_URL'))
	  	);
	}

	outboundReturnRemove(id: any): Observable<any> {
	  return this.http.delete(this.PACKAGESOUTBOUNDRETURNREMOVE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESOUTBOUNDRETURNREMOVE_URL'))
	  	);
	}

	getRsvpDetails(code: any): Observable<any> {
	  return this.http.get(this.PICKUPRSVPDETAILS_URL + '?code=' + code)
	  	.pipe(
	    	catchError(this.handleError<JSON>('PACKAGESPICKUPCUSTOMEROPTIONS_URL'))
	  	);
	}
	

	outboundReturnWhConfirm(param: any): Observable<any> {
		return this.http.post(this.PACKAGESOUTBOUNDWHCONFIRM_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('PACKAGESOUTBOUNDRETURN_URL'))
  		);
	}

	outboundReturnOnHold(param: any): Observable<any> {
		return this.http.post(this.PACKAGESOUTBOUNDONHOLD_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('PACKAGESOUTBOUNDONHOLD_URL'))
  		);
	}

	outboundRtsComplete(param: any): Observable<any> {
		return this.http.post(this.PACKAGESOUTBOUNDRTS_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('PACKAGESOUTBOUNDRTS_URL'))
  		);
	}

	hubOptions(): Observable<any> {
		return this.http.get(this.PACKAGESHUBOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubOptions'))
  		);
	}

	hubCreate(param: any): Observable<any> {
		return this.http.post(this.PACKAGESHUBCREATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubCreate'))
  		);
	}

	hubGetAll(): Observable<any> {
		return this.http.get(this.PACKAGESHUBSGET_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubOptions'))
  		);
	}

	hubToggleActive(id): Observable<any> {
		return this.http.get(this.PACKAGESHUBTOGGLEACTIVE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubOptions'))
  		);
	}

	hubUpdate(param: any): Observable<any> {
		return this.http.post(this.PACKAGESHUBUPDATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubCreate'))
  		);
	}

	hubUserActivate(id): Observable<any> {
		return this.http.get(this.PACKAGESHUBUSERACTIVATE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubOptions'))
  		);
	}

	hubUserToggleBan(id): Observable<any> {
		return this.http.get(this.PACKAGESHUBUSERTOGGLEBAN_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubOptions'))
  		);
	}

	hubUserUpdateRole(param): Observable<any> {
		return this.http.post(this.PACKAGESHUBUSERUPDATEROLE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('hubOptions'))
  		);
	}

	
	








	// LAZADA METHODS
	lazadaPackageSearchOptions(): Observable<any> {
	  return this.http.get(this.LAZADAPACKAGESEARCHOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('lazadaPackageSearchOptions'))
	  	);
	}

	lazadaPackageScanOptions(): Observable<any> {
	  return this.http.get(this.LAZADAPACKAGESCANOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('lazadaPackageScanOptions'))
	  	);
	}

	lazadaPackageScan(param: any): Observable<any> {
		return this.http.post(this.LAZADAPACKAGESCAN_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('lazadaPackageScan'))
  		);
	}

	lazadaPackageComment(param: any): Observable<any> {
		return this.http.post(this.LAZADAPACKAGECOMMENT_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('lazadaPackageComment'))
  		);
	}

	lazadaPackageCommentBreach(param: any): Observable<any> {
		return this.http.post(this.LAZADAPACKAGECOMMENTBREACH_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('lazadaPackageCommentBreach'))
  		);
	}

	

	lazadaPackageDetails(code): Observable<any> {
	  return this.http.get(this.LAZADAPACKAGEDETAILS_URL + code, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('lazadaPackageScanOptions'))
	  	);
	}

	lazadaPackageTimeline(code): Observable<any> {
	  return this.http.get(this.LAZADAPACKAGEDETAILS_URL + code + this.LAZADAPACKAGETIMELINESLUG_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('lazadaPackageScanOptions'))
	  	);
	}

	lazadaPackageInTransit(): Observable<any> {
	  return this.http.get(this.LAZADAPACKAGEINTRANSIT_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('lazadaPackageInTransit'))
	  	);
	}

	lazadaOrdersReportIndex(): Observable<any> {
		return this.http.get(this.LAZADAORDERSREPORTINDEX, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('lazadaOrdersReport'))
  		);
	}

	lazadaOrdersReport(param: any): Observable<any> {
		return this.http.post(this.LAZADAORDERSREPORT, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('lazadaOrdersReport'))
  		);
	}

	lazadaOrdersReportBulk(param): Observable<any> {
		return this.http.post(this.LAZADAORDERSREPORTBULK, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('lazadaOrdersReportBulk'))
  		);
	}

	lazadaStatusReport(param: any): Observable<any> {
		return this.http.post(this.LAZADASTATUSREPORT, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('lazadaStatusReport'))
  		);
	}


	lazadaTrackingDetails(code: any): Observable<any> {
	  // return this.http.get(this.LAZADATRACKINGDETAILS_URL + code)
	  // 	.pipe(
	  //   	catchError(this.handleError<JSON>('lazadaTrackingDetails'))
	  // 	);

	  	return this.http.get(this.LAZADATRACKINGDETAILS_URL + '?code=' + code)
	  	.pipe(
	    	catchError(this.handleError<JSON>('lazadaTrackingDetails'))
	  	);
	}

	
	shuntDbOverall(param): Observable<any> {
	  return this.http.post(this.SHUNTDASHBOARDUD_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('shuntDbOverall'))
	  	);
	}

	adminBulkFailed(param): Observable<any> {
	  return this.http.post(this.ADMINBULKSTATUSFAILED_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('adminBulkFailed'))
	  	);
	}

	adminBulkReship(param): Observable<any> {
	  return this.http.post(this.ADMINBULKSTATUSRESHIP_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('adminBulkReship'))
	  	);
	}

	getMuDetails(id): Observable<any> {
	  return this.http.get(this.GETMOBILEUNIT_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getMuDetails'))
	  	);
	}

	saveMuDetails(param): Observable<any> {
	  return this.http.post(this.SAVEMOBILEUNIT_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('saveMuDetails'))
	  	);
	}

	addIntoMu(param): Observable<any> {
	  return this.http.post(this.ADDINTOMU_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('addIntoMu'))
	  	);
	}

	removeFromMu(param): Observable<any> {
	  return this.http.post(this.REMOVEFROMMU_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('removeFromMu'))
	  	);
	}

	transferMu(param): Observable<any> {
	  return this.http.post(this.TRANSFERMU_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('transferMu'))
	  	);
	}

	reportMu(): Observable<any> {
	  return this.http.get(this.REPORTMU_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('reportMu'))
	  	);
	}










	businessJobOptions(): Observable<any> {
	  return this.http.get(this.BUSINESSJOBOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('businessJobOptions'))
	  	);
	}

	businessCreate(param): Observable<any> {
	  return this.http.post(this.BUSINESSJOBCREATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('businessCreate'))
	  	);
	}

	businessJobs(param): Observable<any> {
	  return this.http.get(this.BUSINESSJOBS_URL, { headers: this.headers(), params: param })
	  	.pipe(
	    	catchError(this.handleError<JSON>('businessJobJobs'))
	  	);
	}

	businessGet(id): Observable<any> {
	  return this.http.get(this.BUSINESSJOBSGET_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('businessUpdate'))
	  	);
	}

	businessUpdate(params): Observable<any> {
	  return this.http.patch(this.BUSINESSJOBUPDATE_URL, params, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('businessUpdate'))
	  	);
	}

	businessPayment(params): Observable<any> {
	  return this.http.post(this.BUSINESSJOBPAYMENT_URL, params, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('businessPayment'))
	  	);
	}

	businessComment(params): Observable<any> {
	  return this.http.post(this.BUSINESSJOBCOMMENT_URL, params, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('businessPayment'))
	  	);
	}

	businessStatus(params): Observable<any> {
	  return this.http.patch(this.BUSINESSJOBSTATUS_URL, params, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('businessPayment'))
	  	);
	}

	businessAddressDocument(param: any): Observable<any> {
	  return this.http.post(this.BUSINESSJOBDOCUMENTUPLOAD_URL, param, { headers: this.uploadHeaders() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('adminProductUploadImage'))
	  	);
	}

	businessAddressDocumentDelete(id: any): Observable<any> {
	  return this.http.delete(this.BUSINESSJOBDOCUMENTDELETE_URL + id, { headers: this.uploadHeaders() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('adminProductUploadImage'))
	  	);
	}




	private handleError<JSON> (operation = 'operation', result?: JSON) {
	  return (error: any): Observable<JSON> => {

	    if(error.status === 401) {

  			localStorage.removeItem('user');
  			this.router.navigate(['/login']);
  		}
	    result = error.error;
	    return of(result as JSON);
  	};
}


}
