import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } 					from  '../api.service';
import { Router} 						from '@angular/router';
import { ExcelService }                       from '../excel.service';
import { ngCopy } from 'angular-6-clipboard';

declare function openModal(): any;
declare function closeModal(): any;

@Component({
  selector: 'app-outbound-transfer',
  templateUrl: './outbound-transfer.component.html',
  styleUrls: ['./outbound-transfer.component.css'],
  providers: [ExcelService]
})
export class OutboundTransferComponent implements OnInit {

  options:any;
    data:any;
    orders:any;

    trackingNumber:any = null;
    resultsLength = 1;
    updateWeight = false;

    submitted = false;

    totalLoaded = 0;
    ordersCount = 0;

    form: FormGroup;
    deliveries: null;

    showTrackingInput = false;
    showWeightInput = false;

    element: HTMLElement;
    failedOrders: Array<String> = [];
    audio:any;

    muActionType = '';
    muExist = false;
    muID = '';
    musubmitted = false;
    muData : any;
    muType = "";
    muTn = "";
    muCount = 0;

    keys: any;
    groupByName: any;
    copyWithTn = false;


    constructor(
        private apiService: ApiService,
        private router: Router,
        private fb: FormBuilder,
        private excelService: ExcelService
        ) { 
        this.audio = new Audio('assets/files/errorsound.mp3');
        this.audio.load();
    }


    get f() { return this.form.controls; }

    ngOnInit() {

        this.form = this.fb.group({
            plate_number: ['', [Validators.required]],
            hub_location_id: ['', [Validators.required]],
            delivery_id: ['',     []],
            tracking_number: ['', []],
            package_weight: ['', [Validators.pattern(/^[.\d]+$/)]],
            // status_id: ['', []],
            // hub_location_id: ['', []],
            // start_date: ['', []],
            // end_date: ['', []],
        });

        setTimeout(()=>{
            document.getElementById("plate_number").focus();
        },0);

        this.index();
    }

    // index(): void {

    //     this.apiService.lazadaPackageSearchOptions().subscribe(data => {

    //         // console.log(data);
    //         this.options 		= data.data;
    //     });
    // }

    index(): void {

        this.apiService.registerOptions().subscribe(data => {

            this.options         = data.data;

            if(this.options.locations.length === 1)
                this.form.controls['hub_location_id'].setValue(this.options.locations[0].id);
        });
    }

    start() {

        this.submitted = true;

        if(this.form.valid) {

            if(this.form.controls['tracking_number'].value !== '' && this.form.controls['package_weight'].value === '' && this.updateWeight)
                setTimeout(()=>{
                    document.getElementById("package_weight").focus();
                },0);
            else {

                this.apiService.outboundTransfer(this.form.getRawValue()).subscribe(data => {

                    if (data.status) {
                        if(this.showTrackingInput)
                            this.totalLoaded++;

                        if('data' in data && 'deliveries' in data.data) {
                            this.deliveries = data.data.deliveries;

                            if(data.data.deliveries.length > 0) {
                                this.form.controls['delivery_id'].setValue(data.data.deliveries[0].id);                                
                            }

                            this.showTrackingInput = true;
                        }

                        if(this.showTrackingInput) {
                            setTimeout(()=>{
                                this.form.controls['tracking_number'].setValue('');
                                this.form.controls['package_weight'].setValue('');
                                this.form.controls['plate_number'].disable();
                                this.form.controls['hub_location_id'].disable();
                                
                                document.getElementById("tracking_number").focus();
                                this.loadTransferOrders(this.form.controls['delivery_id'].value);
                            },0);
                        }
                    }    
                    else {
                        this.audio.play();
                        alert(data.message);
                        this.form.controls['tracking_number'].setValue('');
                        this.form.controls['package_weight'].setValue('');

                        setTimeout(()=>{
                            document.getElementById("tracking_number").focus();
                        },0);
                    }
                });
            }
        }
    }

    toggleUpdateWeight(): void {
        this.updateWeight = !this.updateWeight;
        
        if(!this.updateWeight) {
            this.showWeightInput = false;
            this.form.controls['package_weight'].setValue('');
            
            setTimeout(()=>{
                document.getElementById("tracking_number").focus();
            },0);   
        }
        else {
            this.showWeightInput = true;
            
            if(this.form.controls['tracking_number'].value !== '') {
                this.form.controls['package_weight'].setValue('');
                setTimeout(()=>{
                    document.getElementById("package_weight").focus();
                },0);   
            }
            else 
                setTimeout(()=>{
                    document.getElementById("tracking_number").focus();
                },0);   
        }
    }

    onChange(event) {
        this.form.controls['delivery_id'].setValue(event.target.value);

        setTimeout(()=>{
            document.getElementById("tracking_number").focus();
        },0);

        this.showTrackingInput = true;

        if(event.target.value !== '')
            this.loadTransferOrders(event.target.value);
        else {
            this.orders = null
            this.ordersCount = 0;
        }
    }

    loadToBulk() {

        (<HTMLInputElement>document.getElementById("tnModel")).value = '';

        let tnList = "";

        for (var i = this.muData.mobile_unit_orders.length - 1; i >= 0; i--) {

            tnList += this.muData.mobile_unit_orders[i].order.trackingNumber;
            if(i != 0)
                 tnList += "\n";
        }
        (<HTMLInputElement>document.getElementById("tnModel")).value = tnList;
    }

    startBulk() {

      // this.orders = [];
      
      let values =  (<HTMLInputElement>document.getElementById("tnModel")).value.split("\n");

      if(values.length > 0) {
        let orders: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            orders.push({'trackingNumber': values[i]});
        }

        // this.orders = this.orders.concat(orders);
          this.bulkOutbound(orders, 0);
      }
      
    }

    bulkOutbound(orders, index) {


        this.form.controls['tracking_number'].setValue(orders[index]['trackingNumber']);

           this.apiService.outboundTransfer(this.form.getRawValue()).subscribe(data => {

              if (data.status) {

                if('data' in data && 'deliveries' in data.data) {
                    this.deliveries = data.data.deliveries;

                    if(data.data.deliveries.length > 0) {
                        this.form.controls['delivery_id'].setValue(data.data.deliveries[0].id);                                
                    }

                    // this.showTrackingInput = true;
                }

                // if((index+1) < orders.length) {
                //       this.bulkOutbound(orders, index+1);  
                // }
                // else {
                //   this.resetForm();
                // }
              }  
              else {
                this.failedOrders.push(orders[index]['trackingNumber']+' | '+data.message); 
              }


              if((index+1) < orders.length) {
                      this.bulkOutbound(orders, index+1);  
                }
                else {
                  this.resetForm();
                  this.loadTransferOrders(this.form.controls['delivery_id'].value);
              }

            });
    }

    onHubChange(event) {
        this.form.controls['hub_location_id'].setValue(event.target.value);

        // setTimeout(()=>{
        //     document.getElementById("tracking_number").focus();
        // },0);

        // this.showTrackingInput = true;

        // if(event.target.value !== '')
        //     this.loadTransferOrders(event.target.value);
        // else {
        //     this.orders = null
        //     this.ordersCount = 0;
        // }
    }

    loadTransferOrders(id):void {

        if(id !== '') {
            this.orders         = null;
            this.ordersCount    = 0;

            this.apiService.outboundTransferOrders(id).subscribe(data => {

                this.orders         = data.data;
                if(this.orders)
                    this.ordersCount    = this.orders.length;
            });
        }
    }

    removeTransferOrder(id):void {

        let self = this;
        this.apiService.outboundTransferRemove(id).subscribe(data => {
            this.totalLoaded--;
            self.loadTransferOrders(this.form.controls['delivery_id'].value);
        });

    }

    finish() {
        this.submitted = false;
        this.showTrackingInput = false

        this.totalLoaded = 0;
        this.deliveries = null;

        this.form.controls['plate_number'].enable();
        this.form.controls['plate_number'].setValue('');
        this.form.controls['hub_location_id'].enable();
        this.form.controls['hub_location_id'].setValue('');
        this.form.controls['delivery_id'].setValue('');
        this.form.controls['tracking_number'].setValue('');
        this.form.controls['package_weight'].setValue('');

        this.data = null;
        this.orders = null;
        this.ordersCount = 0;

        setTimeout(()=>{
            document.getElementById("plate_number").focus();
        },0);
    }

  clearFailed() {

    this.failedOrders = [];
  }

    resetForm() {
    
    this.form.controls['tracking_number'].setValue('');
    (<HTMLInputElement>document.getElementById("tnModel")).value = '';

    this.orders = [];
  }

  downloadManifest() {

      if(this.orders && this.orders.length > 0) {
          let tnarray = []

          for (var i = this.orders.length - 1; i >= 0; i--) {
              tnarray.push({"Tracking Number": this.orders[i].order.trackingNumber})
          }

          let locations = this.options.locations;
          var hubname = locations.find( loc => loc['id'] == this.form.controls['hub_location_id'].value );

          this.excelService.exportAsExcelFile(tnarray, 'hubtransfer_'+hubname['name']+'_'+this.orders.length);    
      }
  }

  getMu() {
      // this.musubmitted = true;
      if(this.muID === '')
          alert('Mobile unit ID cannot be empty.')
      else
          this.getMuDetails(this.muID);
  }

  getMuDetails(muID) {

    // this.inboundForm.controls['tracking_number'].setValue(orders[index]['trackingNumber']);

       this.apiService.getMuDetails(muID).subscribe(data => {

           this.musubmitted = true;
          
          if (data.status) {
                this.muExist = true;
                this.muData = data.data;
                this.muCount = data.data.muCount;
                this.loadStates();
          }  
          else {
            this.muExist = false;
            alert(data.message);
          }
        });
  }

  clearMu() {

      this.musubmitted = false;
      this.muExist = false;
      this.muData = null;
      this.muID = '';
      this.muCount = 0;
      (<HTMLInputElement>document.getElementById("tnModel")).value = '';
      // this.muTn = '';
  }

  loadStates() {

        var groupByName = {};

        // this.statsLength = array.length;
        // this.statsStatus = status;
        // this.statsAging = aging;

        // if(this.hubView) {

        //     if(aging === 7)
        //         this.statsAging = 'Breach';
        //     else if(aging === 6)
        //         this.statsAging = 'Pre-Breach';
        // }

        this.muData.mobile_unit_orders.forEach(function (a) {
            groupByName [a.order.customer_address.province] = groupByName [a.order.customer_address.province] || [];
            groupByName [a.order.customer_address.province].push(a);
        });

        var keys = Object.keys(groupByName);

        // console.log(keys)
        // console.log(groupByName)

        this.keys = keys;
        this.groupByName = groupByName;

        // openModal();
    }

    openPopup() {
        openModal();
    }

    closePopup() {

        closeModal();
    }

    copy(type, object, key) {

        if(type === 'hub') {

            let content = 'MU Spot Check' + '\n\n' + 
                // 'Last Status: '     + this.statsStatus + '\n' +
                // 'Aging (Days): '     + this.statsAging + '\n' +  
                'Wrong Location: '         + key + '\n' + 
                'Total Items: '     + object[key].length + '\n' +
                'System Guide: '     + 'Recheck all TNs for wrong state.';

            if(this.copyWithTn) {
                content += '\n\n' + 'List of Tracking Numbers:' + '\n'

                for (var i = 0;  i < object[key].length; i++) {
                    content+= (i+1) + '. ' + object[key][i]['order']['trackingNumber'] + '\n'
                }
            }

            ngCopy(content);
            alert('Details copied');
        }
        else if(type === 'hub') {

        }
    }
}
