import { BrowserModule }                from '@angular/platform-browser';
import { NoopAnimationsModule }         from '@angular/platform-browser/animations';
import { Component, NgModule }                     from '@angular/core';
import { FormsModule }                  from '@angular/forms';
import { HttpClientModule }             from  '@angular/common/http';
import { MomentModule }                 from 'angular2-moment';
import { LoadingBarHttpClientModule }   from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule }       from '@ngx-loading-bar/router';
import { NgbModule }                    from '@ng-bootstrap/ng-bootstrap';
import { ngfModule, ngf }               from 'angular-file';
import { RouterModule, Routes }         from '@angular/router';

import { BoolToTextPipe }               from './boolean-text.pipe';

import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
// import { NgxEditorModule } from 'ngx-editor';
// import { QuillEditorModule } from 'ngx-quill-editor';

import { NgValidatorsModule } from '@ng-validators/ng-validators';

import { ReactiveFormsModule } from '@angular/forms';

import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { SlickCarouselModule }         from 'ngx-slick-carousel';
import {AccordionModule} from "ngx-accordion";
// import { NgbdModalContent }         from './shunt/shunt.component';
// import { ModalModule } from 'ngx-bootstrap/modal';


// import { ShareModule } from '@ngx-share/core';


// import { BotDetectCaptchaModule }       from 'angular-captcha';

import { BsDropdownModule }             from 'ngx-bootstrap/dropdown';
// import { NgxChartsModule }from '@swimlane/ngx-charts';


// import { NgQrScannerModule } from 'angular2-qrscanner';



// OLD

import { ProfileSidebarComponent }      from './profile-sidebar/profile-sidebar.component';
import { CorporateSidebarComponent }    from './corporate-sidebar/corporate-sidebar.component';
import { ResetPasswordComponent }            from './reset-password/reset-password.component';
import { AccountSettingsComponent }          from './account-settings/account-settings.component';
import { AccountSidebarComponent }           from './account-sidebar/account-sidebar.component';
import { ReportIssueComponent }              from './report-issue/report-issue.component';
import { Globals }                           from './globals';


// NEW
import { AppComponent }                 from './app.component';
import { HeaderComponent }              from './header/header.component';
import { FooterComponent }              from './footer/footer.component';
import { AppRoutingModule }             from './app-routing.module';
import { SigninComponent }              from './signin/signin.component';
import { SignupComponent }              from './signup/signup.component';
import { UserActivationComponent }           from './user-activation/user-activation.component';
import { ResetPasswordCompleteComponent }    from './reset-password-complete/reset-password-complete.component';
import { DashboardComponent }                from './dashboard/dashboard.component';
import { AddNewComponent }                 from './supply/add-new/add-new.component';
import { SearchComponent }                 from './supply/search/search.component';
import { ProfileComponent }                 from './user/profile/profile.component';
import { LazadaDashboardComponent }         from './lazada/lazada-dashboard/lazada-dashboard.component';
import { LazadaSearchComponent }            from './lazada/lazada-package/lazada-search/lazada-search.component';
import { LazadaScanComponent }              from './lazada/lazada-package/lazada-scan/lazada-scan.component';
import { LazadaInTransitComponent }         from './lazada/lazada-package/lazada-in-transit/lazada-in-transit.component';
import { LazadaOrderComponent }             from './lazada/lazada-package/lazada-order/lazada-order.component';
import { LazadaOrderTimelineComponent }     from './lazada/lazada-package/lazada-order-timeline/lazada-order-timeline.component';

import { LazadaOrdersComponent }             from './lazada/lazada-reports/lazada-orders/lazada-orders.component';
import { LazadaStatusComponent } from './lazada/lazada-reports/lazada-status/lazada-status.component';
import { LazadaZipcodeComponent } from './lazada/lazada-dashboard/lazada-zipcode/lazada-zipcode.component';
import { ShuntComponent } from './shunt/shunt.component';
import { InboundComponent } from './inbound/inbound.component';
import { OutboundDeliveryComponent } from './outbound-delivery/outbound-delivery.component';
import { OutboundTransferComponent } from './outbound-transfer/outbound-transfer.component';
import { OutboundReturnComponent } from './outbound-return/outbound-return.component';
import { PickupOther3plComponent } from './pickup-other3pl/pickup-other3pl.component';
import { PickupShipperComponent } from './pickup-shipper/pickup-shipper.component';
import { ImportOrdersExcelComponent } from './import-orders-excel/import-orders-excel.component';
import { SortByPipe } from './sort-by.pipe';
import { PickupCustomerComponent } from './pickup-customer/pickup-customer.component';
import { RsvpComponent } from './rsvp/rsvp.component';
import { OutboundWarehouseComponent } from './outbound-warehouse/outbound-warehouse.component';
import { LazadaTrackingComponent } from './lazada/lazada-tracking/lazada-tracking.component';
import { HubLocationsComponent } from './hub-locations/hub-locations.component';
import { BulkStatusComponent } from './lazada/bulk-status/bulk-status.component';

import { AdsenseModule } from 'ng2-adsense';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { NgxBarcodeModule } from 'ngx-barcode';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { B2bJobsComponent } from './b2b/b2b-jobs/b2b-jobs.component';
import { B2bJobComponent } from './b2b/b2b-job/b2b-job.component';
import { B2bReportsComponent } from './b2b/b2b-reports/b2b-reports.component';
import { B2bJobUpdateComponent } from './b2b/b2b-job-update/b2b-job-update.component';
import { B2bJobViewComponent } from './b2b/b2b-job-view/b2b-job-view.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    BsDropdownModule.forRoot(),
    MomentModule,
    NgbModule.forRoot(),
    ngfModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgValidatorsModule,
    ReactiveFormsModule,
    TagInputModule,
    BrowserAnimationsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    // SlickCarouselModule,
    // NgxChartsModule,
    AccordionModule,

    AdsenseModule.forRoot({
      adClient: 'ca-pub-2811963875269575',
      adSlot: 9104368864,
      adFormat: 'horizontal',
      adtest: 'true'
    }),

    NgxBarcodeModule,
    NgxQRCodeModule,
    GooglePlaceModule
    // ModalModule.forRoot(),
    // NgQrScannerModule.forRoot(),
    // ShareModule
    // NgxEditorModule
    // BotDetectCaptchaModule.forRoot({
    //     captchaEndpoint: 'captcha-endpoint/simple-botdetect.php',
    // })
  ],
  declarations: [
    BoolToTextPipe,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SignupComponent,
    SigninComponent,
    ProfileSidebarComponent,
    CorporateSidebarComponent,
    ResetPasswordComponent,
    AccountSettingsComponent,
    AccountSidebarComponent,
    ReportIssueComponent,
    UserActivationComponent,
    ResetPasswordCompleteComponent,
    DashboardComponent,
    AddNewComponent,
    SearchComponent,
    ProfileComponent,
    LazadaDashboardComponent,
    LazadaSearchComponent,
    LazadaScanComponent,
    LazadaInTransitComponent,
    LazadaOrderComponent,
    LazadaOrderTimelineComponent,
    LazadaOrdersComponent,
    LazadaStatusComponent,
    LazadaZipcodeComponent,
    ShuntComponent,
    InboundComponent,
    OutboundDeliveryComponent,
    OutboundTransferComponent,
    OutboundReturnComponent,
    PickupOther3plComponent,
    PickupShipperComponent,
    ImportOrdersExcelComponent,
    SortByPipe,
    PickupCustomerComponent,
    RsvpComponent,
    OutboundWarehouseComponent,
    LazadaTrackingComponent,
    HubLocationsComponent,
    BulkStatusComponent,
    B2bJobsComponent,
    B2bJobComponent,
    B2bReportsComponent,
    B2bJobUpdateComponent,
    B2bJobViewComponent,
    // NgbdModalContent,
    
  ],
  providers: [
      Globals,
      {
        provide: RECAPTCHA_SETTINGS,
        useValue: {
          siteKey: '6LcofG0aAAAAAJHZPLbJOYgDnhRM77StAR1Pagxp', //6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
        } as RecaptchaSettings,
      },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// platformBrowserDynamic().bootstrapModule(AppModule);
