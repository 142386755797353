import { Component, OnInit } from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute} 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

import {Globals} 			from '../globals';

declare function toggleMenu(type): any;
declare function ready(): any;


@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	loggedIn: boolean;
	user: any;
	// client: any;
	// hubManager: any;
	// hubOps: any;
	// staff: any;
	role: any;

	globals: any;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private global: Globals
	) { }
		

	ngOnInit() {
		ready();
		this.isLoggedIn();
		// this.verifyUser(this.router.url);
	}

	toggle(type): void {

		toggleMenu(type);
	}

	isLoggedIn(): void {

		if (localStorage.getItem('user')) {
			this.user = JSON.parse(localStorage.getItem('user'));
			this.loggedIn =  true;

		} else {
			this.loggedIn =  false;
		}
		this.globals 	= this.global;
		this.role 	= this.user.roles[0].slug;
	}

	logout(): void {

    	localStorage.removeItem('user');
		// localStorage.removeItem('user.company');

		this.router.navigate(['/']);
	}

	onEnter(event) {

		if(event.target.value.substring(0, 5) == 'UDB2B') {
			this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
	   		this.router.navigate(['/business/jobs/'+event.target.value]));			
		}
		else {
			this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
	   		this.router.navigate(['/packages/'+event.target.value]));
		}


		// this.router.navigate(['/']);
		// this.router.navigate(['/packages/'+event.target.value]);
	}
}
