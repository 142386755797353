import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } 					from  '../../api.service';
import { Router} 						from '@angular/router';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {


	type: any = '';
	criteria: any = '';

	results:any;

	submitted = false;

	searchForm: FormGroup;

	constructor(
		private apiService: ApiService,
		private router: Router,
		private fb: FormBuilder
	) { }

	get sf() { return this.searchForm.controls; }

	ngOnInit() {

		this.searchForm = this.fb.group({
			search_value: ['', [Validators.required]],
			search_type: ['', [Validators.required]],
			supply_type: ['', [Validators.required]],
		});
	}

	onChange(value): void {
		this.type = value;

		if(this.type === 'company')
			this.criteria = 'ssm_name';
		else if(this.type === 'staff')
			this.criteria = 'full_name';
		else if(this.type === 'transport')
			this.criteria = 'plate_number';
		else
			this.criteria = '';

		this.searchForm.controls['search_type'].setValue(this.criteria);
	}

	search(): void {

		this.submitted = true;

		if (this.searchForm.valid) {
			if(this.type === 'company') {
				this.apiService.companySearch(this.searchForm.value).subscribe(data => {

					if (data.status) {
						this.reset();
						this.results = data.data;	
					}
				});
			}
			else if(this.type === 'staff') {
				this.apiService.staffSearch(this.searchForm.value).subscribe(data => {

					if (data.status) {
						this.reset();
						this.results = data.data;
					}
				});
			}
			else if(this.type === 'transport') {
				this.apiService.transportSearch(this.searchForm.value).subscribe(data => {

					if (data.status) {
						this.reset();
						this.results = data.data;
					}
				});
			}
		}
	}

	reset(): void {

		var criteria = this.searchForm.controls['search_type'].value;
		
		this.searchForm.reset();
		this.submitted = false;

		this.searchForm.controls['search_type'].setValue(criteria);
		this.searchForm.controls['supply_type'].setValue(this.type);
	}
}