/// <reference types="google.maps" />
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } 					from  '../../api.service';
import { Router} 						from '@angular/router';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { ActivatedRoute}         from '@angular/router';
// import {} from 'google.maps';

// declare var google: any;
// declare var map: any;
declare function openModal(): any;
declare function closeModal(): any;

@Component({
  selector: 'app-b2b-job-view',
  templateUrl: './b2b-job-view.component.html',
  styleUrls: ['./b2b-job-view.component.css']
})
export class B2bJobViewComponent implements OnInit {

  options: any;
	
	form: FormGroup;
	addForm: FormGroup;
	
	submitted = false;
	addSubmitted = false;

	addresses = []
	pods = []
	company:any
  job:any
  jobId:any
  comment:any

  bill_ref = '';
  vendor_ref = '';
  manpower_ref = '';

  filesToUpload:any = []
  filesUploaded:any = []
  uploadAddress:any
  uploadFilename:any
  modalType = ''

	// map: google.maps.Map;

	autoCompleteOptions = {
	    fields: ["address_components", "geometry", "name", "formatted_address"],
	    // types: ["address"],
	    componentRestrictions: { country: 'MY' }
	  }
  constructor(private apiService: ApiService,
		private router: Router,
		private fb: FormBuilder,
    private route: ActivatedRoute
	) { }

  get f() { return this.form.controls; }
  get af() { return this.addForm.controls; }

  ngOnInit() {
    window.scroll(0,0);

    this.jobId = this.route.snapshot.paramMap.get('id');

  	initMap();
  	
  	this.form = this.fb.group({
        id: 		      ['', [Validators.required]],
        start_date:     ['', [Validators.required]],
        client_id:    ['', [Validators.required]],
        vendor_id: 		['', [Validators.required]],
        staff_id: 		['', [Validators.required]],
        transport_id: ['', [Validators.required]],
        bill_amount: 	['', [Validators.required]],
        vendor_cost: 	['', [Validators.required]],
        manpower_cost:[0, [Validators.required]],
        addresses:    [this.addresses, []],
    });

    this.addForm = this.fb.group({
        full_address:  ['', [Validators.required]],
		    address_1:     ['', [Validators.required]],
        address_2:     ['', [Validators.required]],
        city: ['',     [Validators.required]],
        state_id:      ['', [Validators.required]],
        lat: ['',      [Validators.required]],
        long: ['',     [Validators.required]],
        zipcode: ['',  [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5)]],
        type: ['',     [Validators.required]],
    });

    this.index()
    
  }

  index() {
  	this.apiService.businessJobOptions().subscribe(data => {

		  this.options 		= data.data;
      this.get()

	  });
  }

  get() {
    
    this.apiService.businessGet(this.jobId).subscribe(data => {

      this.job       = data.data;
      this.company   = this.options.companies.find(x => x.id == this.job.vendor_id);
      this.form.patchValue(this.job);
      this.addresses = this.job.addresses
      markers(this.addresses)
    })
  }

  update() {
  	this.submitted = true;

    if(this.form.valid) {
      this.apiService.businessUpdate(this.form.getRawValue()).subscribe(data => {

          if (data.status) {
            this.submitted = false;
            this.form.reset();            
            this.addresses = [];
            markers(this.addresses)
          }
          
          alert(data.message);
        });
    }
  }

  updateStatus(type) {

      let param = {
        'order_number': this.job.order_number,
        'status'      : type
      }
      this.apiService.businessStatus(param).subscribe(data => {

          if (data.status) {
            this.get()
          }
          
          alert(data.message);
        });
    }

  addPayment(type) {
		
		let ref = ''
		
		if(type == 'bill_ref')
			ref = this.bill_ref
		else if(type == 'vendor_ref')
			ref = this.vendor_ref
		else if(type == 'manpower_ref')
			
			ref = this.manpower_ref
  		let param = {
  			ref_type	: type,
  			ref_number	: ref,
  			id			: this.job.id
  		}

		this.apiService.businessPayment(param).subscribe(data => {

          if (data.status) {
            // this.submitted = false;
            // this.form.reset();            
            // this.addresses = [];
            // markers(this.addresses)
            this.get()
          }
          else
          	alert(data.message);
        });

  }

  fillInAddress(place) {  // optional parameter
    	this.addForm.controls['full_address'].setValue(place.name + ', ' + place.formatted_address)
    	this.addForm.controls['address_1'].setValue(this.getAddressComp(place, 'route'))
        this.addForm.controls['address_2'].setValue(this.getAddressComp(place, 'sublocality_level_1'))
        this.addForm.controls['city'].setValue(this.getAddressComp(place, 'locality'))
        
        this.addForm.controls['zipcode'].setValue(this.getAddressComp(place, 'postal_code'))
        this.addForm.controls['lat'].setValue(place.geometry.location.lat())
        this.addForm.controls['long'].setValue(place.geometry.location.lng())
        this.addForm.controls['state_id'].setValue(this.getStateIdFromName(this.getAddressComp(place, 'administrative_area_level_1')))
	}

  getStateIdFromName(name) {

  	let state = this.options.states.find(x => x.name == name);
  	if(state)
  		return state.id;
  	return null;
  }

  getAddressComp = function (place, type) {
  	const addressNameFormat = {
      'route'						: 'long_name',
      'sublocality_level_1'			: 'long_name',
      'locality'					: 'long_name',
      'administrative_area_level_1'	: 'short_name',
      'country'						: 'long_name',
      'postal_code'					: 'short_name',
    };
      for (const component of place.address_components) {
        if (component.types[0] === type) {
          return component[addressNameFormat[type]];
        }
      }
      return '';
    };

	handleAddressChange(place:any) {
	    this.fillInAddress(place);
	    this.addAddress()
	}

	addAddress() {	
		this.addSubmitted = true;

		if(this.addForm.valid) {
			this.addresses.push(this.addForm.value)
			this.addForm.reset();
			this.addSubmitted = false;
			markers(this.addresses)
		}
	}

	removeAddress(i) {
    if (i !== -1) {
		  this.addresses.splice(i,1);
		  markers([])
      markers(this.addresses)
    }

	}

	onCompanyChange(event) {
        // this.staff = null;
        this.form.controls['staff_id'].setValue('');
        this.form.controls['transport_id'].setValue('');
        
        this.company 	= this.options.companies.find(x => x.id == event.target.value);
        // this.transports = this.options.companies.find(x => x.id == event.target.value);
    }

addComment() {
  let param = {
      'order_number'    : this.job.order_number,
      'comments'        : this.comment
    }

    this.apiService.businessComment(param).subscribe(data => {

      if(data.status) {
        this.comment = '';
        this.get()
      }
      else
        alert(data.message);
    });
}

transform(value:string): string {
    let first = value.substr(0,1).toUpperCase();
    return first + value.substr(1); 
}

onFileChange(evt: any) {

        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length > 0) {
          this.filesToUpload = target.files
          setTimeout(()=>{                           // <<<---using ()=> syntax
                this.uploadDocument()
          }, 1000);
        }
    }

    uploadDocument() {

      let param = new FormData() 
      
      if(this.filesToUpload.length > 0) {
        param.append('document',   this.filesToUpload[0])
        param.append('address_id', this.uploadAddress.id)

        this.uploadFilename = this.filesToUpload[0].name
        
        this.apiService.businessAddressDocument(param).subscribe(data => {

            if (data.status) {
              
              let temp = []

              for (var i = this.filesToUpload.length - 1; i >= 0; i--) {
                
                if(this.filesToUpload[i].name !== this.uploadFilename) {

                  temp.push(this.filesToUpload[i])
                }
                else
                  this.filesUploaded.push(this.filesToUpload[i])
              }
              
              this.filesToUpload = temp;
              
              setTimeout(()=>{                           // <<<---using ()=> syntax
                this.uploadDocument()
              }, 1000);
            }

          });
        }
        else
          this.closePopup()

    }

    deleteDocument(id) {
      this.apiService.businessAddressDocumentDelete(id).subscribe(data => {

          if (data.status) {
            this.uploadAddress['pods'] = this.uploadAddress.pods.filter(x => x.id !== id)
          }
      })
    }

    uploadDocumentModal(type, add) {

      // if(type === 'origin')
      this.uploadAddress = add
      this.modalType = 'upload'
        openModal();
    }

    viewDocumentModal(type, add) {

      // if(type === 'origin')
      this.uploadAddress = add
      this.modalType = 'view'
        openModal();
    }

    closePopup() {


      closeModal();

      this.filesToUpload = []
      this.filesUploaded = []
      this.uploadAddress = null
      
      if(this.modalType === 'upload')
      this.get()
    }

}



function initMap(): void {


  let map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
    center: { lat: 3.1382033262759954, lng: 101.52128863331107 },
    zoom: 12,
    mapTypeControl: false,
  });

  const marker = new google.maps.Marker({
    position: { lat: 3.1382033262759954, lng: 101.52128863331107 },
    map: map,
  });

  const infoWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: false,
    });

   marker.addListener("click", () => {
    infoWindow.setContent('BBC Sortation Centre, Lot 7430, Jalan Ciku, U9 Shah Alam, Selangor.');
    infoWindow.open(map, marker);
  });
  // markers();

  

  // Create an array of alphabetical characters used to label the markers.

  // Add some markers to the map.
  
}

function markers(addresses) {

    let map = new google.maps.Map(document.getElementById("map") as HTMLElement)
    
	const infoWindow = new google.maps.InfoWindow({
	    content: "",
	    disableAutoPan: false,
	  });
  	
  	const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  	if(addresses.length > 0) {
		const markers = addresses.map((position, i) => {
		    const label = labels[i % labels.length];

		    position['lng'] = Number(position['long'])
        position['lat'] = Number(position['lat'])
		    const marker = new google.maps.Marker({
		      position,
		      label,
		    });

		    // markers can only be keyboard focusable when they have click listeners
		    // open info window when marker is clicked
		    marker.addListener("click", () => {
		      infoWindow.setContent(position.full_address);
		      infoWindow.open(map, marker);
		    });

		    return marker;
		});
		
		new MarkerClusterer({ map, markers });

		var latlngbounds = new google.maps.LatLngBounds();
		for (var i = 0; i < addresses.length; i++) {
			addresses[i]['lng'] = Number(addresses[i]['long'])
      addresses[i]['lat'] = Number(addresses[i]['lat'])
		    latlngbounds.extend(addresses[i]);
		}
		map.fitBounds(latlngbounds);
		var listener = google.maps.event.addListener(map, 'idle', function()
        {
          if(map.getZoom() > 16)
            map.setZoom(17);
          google.maps.event.removeListener(listener);
        });
  	}
  	else {
  		map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
		    center: { lat: 3.1382033262759954, lng: 101.52128863331107 },
		    zoom: 16,
		    mapTypeControl: false
		  });
  	}

  // Add a marker clusterer to manage the markers.
  


}
// const locations = [
//   { lat: -31.56391, lng: 147.154312 },
//   { lat: -33.718234, lng: 150.363181 },
//   { lat: -33.727111, lng: 150.371124 },
//   { lat: -33.848588, lng: 151.209834 },
//   { lat: -33.851702, lng: 151.216968 },
//   { lat: -34.671264, lng: 150.863657 },
//   { lat: -35.304724, lng: 148.662905 },
//   { lat: -36.817685, lng: 175.699196 },
//   { lat: -36.828611, lng: 175.790222 },
//   { lat: -37.75, lng: 145.116667 },
//   { lat: -37.759859, lng: 145.128708 },
//   { lat: -37.765015, lng: 145.133858 },
//   { lat: -37.770104, lng: 145.143299 },
//   { lat: -37.7737, lng: 145.145187 },
//   { lat: -37.774785, lng: 145.137978 },
//   { lat: -37.819616, lng: 144.968119 },
//   { lat: -38.330766, lng: 144.695692 },
//   { lat: -39.927193, lng: 175.053218 },
//   { lat: -41.330162, lng: 174.865694 },
//   { lat: -42.734358, lng: 147.439506 },
//   { lat: -42.734358, lng: 147.501315 },
//   { lat: -42.735258, lng: 147.438 },
//   { lat: -43.999792, lng: 170.463352 },
// ];
// declare global {
//   interface Window {
//     initMap: () => void;
//   }
// }
// window.initMap = initMap;
