import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } 					from  '../../../api.service';
import { Router} 						from '@angular/router';

@Component({
  selector: 'app-lazada-scan',
  templateUrl: './lazada-scan.component.html',
  styleUrls: ['./lazada-scan.component.css']
})
export class LazadaScanComponent implements OnInit {

	data:any;
	options:any;

	platformOrderNumber = '';
	failedCount 	= 0;
	successCount 	= 0;
  	updateWeight 	= false;
  	autoTransit 	= true;

  	submitted = false;

	searchForm: FormGroup;

	scannedItems:any = [];

    constructor(
  		private apiService: ApiService,
		private router: Router,
		private fb: FormBuilder
	) { }

	get cf() { return this.searchForm.controls; }

	ngOnInit() {

		this.searchForm = this.fb.group({
	  		transport_id: ['', []],
	      	staff_id: ['', []],
	  		status_id: ['', [Validators.required]],
	  		from_hub_location_id: ['', [Validators.required]],
	  		to_hub_location_id: ['', [Validators.required]],
	  		tracking_number: ['', [Validators.required]],
	  		package_weight: ['', [Validators.pattern(/^[.\d]+$/)]],
	  		failed_id: ['', []],
		});

	  	this.index();
	}

	index(): void {

		this.apiService.lazadaPackageScanOptions().subscribe(data => {

			// console.log(data);
			this.options 		= data.data;
		});
	}

	// submit(): void {

	// 	this.submitted = true;

	// 	if(this.updateWeight) {
	// 		if(this.searchForm.controls['package_weight'].value !== '') {

 //                if(this.searchForm.valid) {
 //                    this.apiService.lazadaPackageUpdateWeight(this.searchForm.value).subscribe(data => {
                    
 //                        if (!data.status){
 //                            alert(data.message);
                            
 //                            setTimeout(()=>{
 //                                document.getElementById("package_weight").focus();
 //                            },0);
 //                        }
 //                        else {
 //                            this.data = null;

 //                            this.searchForm.controls['package_weight'].setValue('');
                            
                            
 //                            setTimeout(()=>{
	//                             document.getElementById("tracking_number").focus();
	//                         },0);


 //                            if(this.autoTransit) {
 //                            	this.scan();
 //                            }
 //                        }
 //                        this.submitted = false;
 //                    });
 //                }
 //            }
 //            else if (this.searchForm.valid) {
 //                this.apiService.lazadaPackageSearch(this.searchForm.value).subscribe(data => {
 //                    if (!data.status){
 //                        this.data = null;
 //                        alert(data.message);
 //                        this.searchForm.controls['tracking_number'].setValue('');
 //                        document.getElementById("tracking_number").focus();
 //                    }
 //                    else {
 //                        this.data = data.data;
 //                        // this.package_weight.nativeElement.focus();
 //                        setTimeout(()=>{
 //                            document.getElementById("package_weight").focus();
 //                        },0);
 //                    }
 //                    this.submitted = false;
 //                });
 //            }
	// 	}
	// 	else
	// 		this.scan();
	// }

	scan(): void {
	
		// else {
			if (this.searchForm.valid) {
				this.apiService.lazadaPackageScan(this.searchForm.value).subscribe(data => {
					if (!data.status) {
						this.failedCount++;
						// alert(data.message);
					}
					else
						this.successCount++;


					this.platformOrderNumber = '';
					
					if(data.data)
						this.platformOrderNumber = data.data.platformOrderNumber;

					this.scannedItems.push({
						'tracking_number': this.searchForm.controls['tracking_number'].value,
						'order_number': this.platformOrderNumber,
						'status': String(""+data.status),
						'message': data.message
					});

					this.submitted = false;
					this.data = null;

					setTimeout(()=>{
						this.searchForm.controls['tracking_number'].setValue('');
                        document.getElementById("tracking_number").focus();
                    },0);
					
				});
			}	
		// }
	}

	toggleUpdateWeight(): void {
        this.updateWeight = !this.updateWeight;

    }

	toggleAutoTransit(): void {
        // this.autoTransit = !this.autoTransit;
        // this.autoTransit = !this.autoTransit;

    }




}
