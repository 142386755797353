import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } 					from  '../../../api.service';
import { Router} 						from '@angular/router';

declare function openModal(): any;
declare function closeModal(): any;

@Component({
    selector: 'app-lazada-search',
    templateUrl: './lazada-search.component.html',
    styleUrls: ['./lazada-search.component.css']
})
export class LazadaSearchComponent implements OnInit {

    options:any;
    data:any;
    resultsLength = 1;
    updateWeight = false;

    submitted = false;

    searchForm: FormGroup;

    scannedItems:any = [];

    constructor(
        private apiService: ApiService,
        private router: Router,
        private fb: FormBuilder
        ) { }


    get cf() { return this.searchForm.controls; }

    ngOnInit() {

        this.searchForm = this.fb.group({
            tracking_number: ['', [Validators.required]],
            package_weight: ['', [Validators.pattern(/^[.\d]+$/)]],
            // status_id: ['', []],
            // hub_location_id: ['', []],
            // start_date: ['', []],
            // end_date: ['', []],
        });

        setTimeout(()=>{
            document.getElementById("tracking_number").focus();
        },0);

            

        this.isLoggedIn();
    }

    isLoggedIn(): void {

        if(localStorage.getItem('user'))
            if(JSON.parse(localStorage.getItem('user')).id === 14)
                openModal();

    }

    closePopup() {

        closeModal();
    }

    // index(): void {

    //     this.apiService.lazadaPackageSearchOptions().subscribe(data => {

    //         // console.log(data);
    //         this.options 		= data.data;
    //     });
    // }

    toggleUpdateWeight(): void {
        this.updateWeight = !this.updateWeight;
    }

    search(): void {

        this.submitted = true;

        if (this.searchForm.valid) {

            if(this.searchForm.controls['package_weight'].value !== '') {
                if(this.searchForm.valid) {
                    this.apiService.updateOrderWeight(this.searchForm.value).subscribe(data => {
                    
                        if (!data.status){
                            alert(data.message);
                            
                            setTimeout(()=>{
                                document.getElementById("package_weight").focus();
                            },0);
                        }
                        else {
                            this.data = null;
                            this.searchForm.reset();
                            
                            setTimeout(()=>{
                                this.searchForm.controls['package_weight'].setValue('');
                                document.getElementById("tracking_number").focus();
                            },0);
                        }
                        this.submitted = false;
                    });
                }
            }
            else {
                this.apiService.searchOrder(this.searchForm.value).subscribe(data => {
                    if (!data.status){
                        this.data = null;
                        alert(data.message);
                        this.searchForm.controls['tracking_number'].setValue('');
                        setTimeout(()=>{
                            document.getElementById("tracking_number").focus();
                        },0);
                    }
                    else {
                        this.data = data.data;

                        if(this.updateWeight) {
                            setTimeout(()=>{
                                document.getElementById("package_weight").focus();
                            },0);
                        }
                        else
                            this.searchForm.controls['tracking_number'].setValue('');

                    }
                    this.submitted = false;
                });
            }
        }
    }
}