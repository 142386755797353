import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService }           from  '../api.service';
import { ExcelService }                       from '../excel.service';

@Component({
  selector: 'app-outbound-warehouse',
  templateUrl: './outbound-warehouse.component.html',
  styleUrls: ['./outbound-warehouse.component.css'],
  providers: [ExcelService]
})
export class OutboundWarehouseComponent implements OnInit {

  form: FormGroup;
  type = 'confirm';
  options:any;
  stats:any;
  lastRefreshed: any;
  lastAssignRefreshed: any;
  pendingAssign: any;
  trips: any;

  submitted = false;

  TNModel: any;
  element: HTMLElement;

  orders: Array<Object> = [];
  failedOrders: Array<String> = [];



  constructor(private fb: FormBuilder,
    private apiService: ApiService,
    private excelService: ExcelService) { }

  get f() { return this.form.controls; }

  ngOnInit() {

  	this.form = this.fb.group({
        tracking_number: ['', [Validators.required]],
        driver_name: ['', [Validators.required]],
        // transport_id: ['', []],
        timestamp: ['', []],
    });

    // this.index();
    this.orders = [];

  }

  // index() {

  //   this.apiService.customerReturnOptions().subscribe(data => {

  //           this.options     = data.data;
  //           this.stats       = this.options.stats;

  //           this.lastRefreshed = new Date();
  //       });
  //   // 
  // }

  // confirmImportedPickup() {
  //   this.apiService.customerReturnConfirmImported().subscribe(data => {

  //       if(data.status)
  //         this.index();
  //   });
  // }

  // confirmReceivedPickup() {
  //   this.apiService.customerReturnConfirmReceived().subscribe(data => {

  //       if(data.status)
  //         this.index();
  //   });
  // }

  // confirmReattempt() {
  //   this.apiService.customerReturnConfirmReattempt().subscribe(data => {

  //       if(data.status)
  //         this.index();
  //   });
  // }

  // endAttemptedTwice() {

  //   // console.log('Ending');
  //    this.apiService.customerReturnEndReattempted().subscribe(data => {
       
  //       if(data.status) {
  //         this.index();
  //         console.log(data);
  //       }
  //   }); 
  // }


  assignIndex() {

    this.orders = [];
    this.pendingAssign = null;

    this.apiService.customerReturnAssignIndex().subscribe(data => {

        if(data.status)
          this.pendingAssign = data.data;

        this.lastAssignRefreshed = new Date();
    });
  }

  // districtSelected(state, city, district, event) {

  //   if(event.target.checked) {

  //     for (var i = this.pendingAssign['orders'][state][city][district].length - 1; i >= 0; i--) {
  //       this.orders.push(this.pendingAssign['orders'][state][city][district][i]);
  //     }
  //   }
  //   else {
  //     for (var i = this.pendingAssign['orders'][state][city][district].length - 1; i >= 0; i--) {
  //       let id = this.orders.indexOf(this.pendingAssign['orders'][state][city][district][i]);
  //       this.orders.splice(id,1);
  //     }
  //   }
  // }

  // onDriverChange(event) {
  //   this.apiService.customerReturnDriverTrips(event.target.value).subscribe(data => {

  //       this.trips       = data.data;

  //       if(this.trips.length == 1)
  //         this.form.controls['delivery_id'].setValue(this.trips[0].id);
  //       else
  //         this.form.controls['delivery_id'].setValue('');
  //   });
  // }

  start() {

  	
    this.submitted = true;

    // if(this.form.valid) {
      // this.form.controls['driver_id'].disable();
      // this.form.controls['delivery_id'].disable();
      // this.form.controls['limit_count'].disable();

      // this.orders = [];
      
      let values =  (<HTMLInputElement>document.getElementById("tnModel")).value.split("\n");

      if(values.length > 0) {
        let orders: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            orders.push({'trackingNumber': values[i]});
        }

        this.orders = this.orders.concat(orders);
      }
      
      this.buldAssignOrder(this.orders, 0);
      this.resetForm();
    
      // console.log(this.orders);
  }

  buldAssignOrder(orders, index) {


    this.form.controls['tracking_number'].setValue(orders[index]['trackingNumber']);

     // this.buldAssignOrder(orders, index+1);

       this.apiService.outboundReturnWhConfirm(this.form.getRawValue()).subscribe(data => {

       		console.log(data.data);
          if (data.status) {

            // if('data' in data) {
            //   this.trips = data.data;

            //   if(this.trips.length > 0)
            //     this.form.controls['delivery_id'].setValue(this.trips[0]['id']);
            // }

            if((index+1) < orders.length) {
              	this.buldAssignOrder(orders, index+1);  
            }
            else {
              this.resetForm();
              // this.trips = null;
            }
          }  
          else {
            // this.resetForm();
            // this.failedOrders = [];
            this.failedOrders.push(orders[index]['trackingNumber']+' | '+data.message);

            this.buldAssignOrder(orders, index+1);
            // alert(data.message);
          }
        });
  }

  clearFailed() {

    this.failedOrders = [];
  }

  resetForm() {
  	(<HTMLInputElement>document.getElementById("tnModel")).value = '';

    this.submitted = false;
    
    this.form.controls['driver_name'].enable();
    this.form.controls['timestamp'].enable();

    this.form.controls['driver_name'].setValue('');
    this.form.controls['timestamp'].setValue('');
    
    this.orders = [];
    // this.assignIndex();
  }

  // revokeUnscheduled(deliveryId) {

  //   this.apiService.customerReturnAssignRevoke(deliveryId).subscribe(data => {

  //       if(data.status)
  //         this.assignIndex();

  //   });
  // }

  download(type) {

    this.apiService.customerReturnDownload(type).subscribe(data => {

      if (data.status) {
          if(data.data.length !== 0)
              this.excelService.exportAsExcelFile(data.data, 'lazada_orders_'+type);
          else
              alert('No orders found to export.');
      } else {
          alert(data.message);
      }

    });
  }

}
