import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } 					from  '../api.service';
import { Router} 						from '@angular/router';

@Component({
  selector: 'app-outbound-return',
  templateUrl: './outbound-return.component.html',
  styleUrls: ['./outbound-return.component.css']
})
export class OutboundReturnComponent implements OnInit {

  options:any;
    data:any;
    orders:any;
    type = 'return';

    trackingNumber:any = null;
    resultsLength = 1;
    updateWeight = false;

    submitted = false;

    totalLoaded = 0;
    ordersCount = 0;

    form: FormGroup;
    deliveries: null;

    showTrackingInput = false;
    showWeightInput = false;

    TNModel: any;
    element: HTMLElement;

    holdOrders: Array<Object> = [];
    failedOrders: Array<String> = [];

    assignType = 'name';

    company: any;
    staff: any;

    constructor(
        private apiService: ApiService,
        private router: Router,
        private fb: FormBuilder
        ) { }


    get f() { return this.form.controls; }

    ngOnInit() {

        this.form = this.fb.group({
            plate_number: ['', []],
            // driver_phone: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
            company_id: ['', [Validators.required]],
            staff_id: ['', [Validators.required]],
            hub_location_id: [6, [Validators.required]],
            delivery_id: ['',     []],
            tracking_number: ['', []],
            package_weight: ['', [Validators.pattern(/^[.\d]+$/)]],
            // status_id: ['', []],
            // hub_location_id: ['', []],
            // start_date: ['', []],
            // end_date: ['', []],
        });

        setTimeout(()=>{
            document.getElementById("plate_number").focus();
        },0);

        this.index();
    }

    // index(): void {

    //     this.apiService.lazadaPackageSearchOptions().subscribe(data => {

    //         // console.log(data);
    //         this.options 		= data.data;
    //     });
    // }

    index(): void {

        this.apiService.outboundReturnOptions().subscribe(data => {

            this.options         = data.data;
        });
    }

    start() {

        this.submitted = true;

        if(this.form.valid) {

            if(this.form.controls['tracking_number'].value !== '' && this.form.controls['package_weight'].value === '' && this.updateWeight)
                setTimeout(()=>{
                    document.getElementById("package_weight").focus();
                },0);
            else {

                this.apiService.outboundReturn(this.form.getRawValue()).subscribe(data => {

                    if (data.status) {
                        if(this.showTrackingInput)
                            this.totalLoaded++;

                        if('data' in data && 'deliveries' in data.data) {
                            this.deliveries = data.data.deliveries;

                            if(data.data.deliveries.length > 0) {
                                this.form.controls['delivery_id'].setValue(data.data.deliveries[0].id);                                
                            }

                            this.showTrackingInput = true;
                        }

                        if(this.showTrackingInput) {
                            setTimeout(()=>{
                                this.form.controls['tracking_number'].setValue('');
                                this.form.controls['package_weight'].setValue('');
                                this.form.controls['plate_number'].disable();
                                this.form.controls['hub_location_id'].disable();
                                this.form.controls['company_id'].disable();
                                this.form.controls['staff_id'].disable();
                                
                                document.getElementById("tracking_number").focus();
                                this.loadReturnOrders(this.form.controls['delivery_id'].value);
                            },0);
                        }
                    }    
                    else {
                        alert(data.message);
                        this.form.controls['tracking_number'].setValue('');
                        this.form.controls['package_weight'].setValue('');

                        setTimeout(()=>{
                            document.getElementById("tracking_number").focus();
                        },0);
                    }
                });
            }
        }
    }

    toggleUpdateWeight(): void {
        this.updateWeight = !this.updateWeight;
        
        if(!this.updateWeight) {
            this.showWeightInput = false;
            this.form.controls['package_weight'].setValue('');
            
            setTimeout(()=>{
                document.getElementById("tracking_number").focus();
            },0);   
        }
        else {
            this.showWeightInput = true;
            
            if(this.form.controls['tracking_number'].value !== '') {
                this.form.controls['package_weight'].setValue('');
                setTimeout(()=>{
                    document.getElementById("package_weight").focus();
                },0);   
            }
            else 
                setTimeout(()=>{
                    document.getElementById("tracking_number").focus();
                },0);   
        }
    }

    onChange(event) {
        this.form.controls['delivery_id'].setValue(event.target.value);

        setTimeout(()=>{
            document.getElementById("tracking_number").focus();
        },0);

        this.showTrackingInput = true;

        if(event.target.value !== '')
            this.loadReturnOrders(event.target.value);
        else {
            this.orders = null
            this.ordersCount = 0;
        }
    }

    onHubChange(event) {
        this.form.controls['hub_location_id'].setValue(event.target.value);

        // setTimeout(()=>{
        //     document.getElementById("tracking_number").focus();
        // },0);

        // this.showTrackingInput = true;

        // if(event.target.value !== '')
        //     this.loadReturnOrders(event.target.value);
        // else {
        //     this.orders = null
        //     this.ordersCount = 0;
        // }
    }

    loadReturnOrders(id):void {

        if(id !== '') {
            this.orders         = null;
            this.ordersCount    = 0;

            this.apiService.outboundReturnOrders(id).subscribe(data => {

                this.orders         = data.data;
                if(this.orders)
                    this.ordersCount    = this.orders.length;
            });
        }
    }

    removeReturnOrder(id):void {

        let self = this;
        this.apiService.outboundReturnRemove(id).subscribe(data => {
            this.totalLoaded--;
            self.loadReturnOrders(this.form.controls['delivery_id'].value);
        });

    }

    finish() {
        this.submitted = false;
        this.showTrackingInput = false

        this.totalLoaded = 0;
        this.deliveries = null;

        this.form.controls['company_id'].enable();
        this.form.controls['company_id'].setValue('');
        this.form.controls['staff_id'].enable();
        this.form.controls['staff_id'].setValue('');
        this.form.controls['plate_number'].enable();
        this.form.controls['plate_number'].setValue('');
        this.form.controls['hub_location_id'].enable();
        // this.form.controls['hub_location_id'].setValue('');
        this.form.controls['delivery_id'].setValue('');
        this.form.controls['tracking_number'].setValue('');
        this.form.controls['package_weight'].setValue('');

        this.data = null;
        this.orders = null;
        this.ordersCount = 0;
        this.company = null;
        this.staff = null;

        setTimeout(()=>{
            document.getElementById("plate_number").focus();
        },0);
    }


    startOnHold() {

      
    // this.submitted = true;

    // if(this.form.valid) {
      // this.form.controls['driver_id'].disable();
      // this.form.controls['delivery_id'].disable();
      // this.form.controls['limit_count'].disable();

      // this.orders = [];
      
      let values =  (<HTMLInputElement>document.getElementById("tnModel")).value.split("\n");

      if(values.length > 0) {
        let orders: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            orders.push({'trackingNumber': values[i]});
        }

        this.orders = orders;
      }
      
      this.buldAssignOrder(this.orders, 0);
      // this.resetForm();
    
      // console.log(this.orders);
    }

    buldAssignOrder(orders, index) {


        this.form.controls['tracking_number'].setValue(orders[index]['trackingNumber']);

         // this.buldAssignOrder(orders, index+1);

           this.apiService.outboundReturnOnHold(this.form.getRawValue()).subscribe(data => {

                   // console.log(data.data);
              if (data.status) {

                // if('data' in data) {
                //   this.trips = data.data;

                //   if(this.trips.length > 0)
                //     this.form.controls['delivery_id'].setValue(this.trips[0]['id']);
                // }

                if((index+1) < orders.length) {
                      this.buldAssignOrder(orders, index+1);  
                }
                else {
                  this.resetOnHoldForm();
                  // this.trips = null;
                }
              }  
              else {
                // this.resetForm();
                // this.failedOrders = [];
                this.failedOrders.push(orders[index]['trackingNumber']+' | '+data.message);

                this.buldAssignOrder(orders, index+1);
                // alert(data.message);
              }
            });
    }

    resetOnHoldForm() {
        (<HTMLInputElement>document.getElementById("tnModel")).value = '';
        
        // this.submitted = false;
        
        // this.form.controls['driver_name'].enable();
        // this.form.controls['timestamp'].enable();

        // this.form.controls['driver_name'].setValue('');
        // this.form.controls['timestamp'].setValue('');
        
        this.orders = null;
        // this.assignIndex();
      }

    resetRtsForm() {
        (<HTMLInputElement>document.getElementById("tnModelRts")).value = '';
        
        // this.submitted = false;
        
        // this.form.controls['driver_name'].enable();
        // this.form.controls['timestamp'].enable();

        // this.form.controls['driver_name'].setValue('');
        // this.form.controls['timestamp'].setValue('');
        
        this.orders = null;
        // this.assignIndex();
      }


    startRts() {

      
    // this.submitted = true;

    // if(this.form.valid) {
      // this.form.controls['driver_id'].disable();
      // this.form.controls['delivery_id'].disable();
      // this.form.controls['limit_count'].disable();

      // this.orders = [];
      
      let values =  (<HTMLInputElement>document.getElementById("tnModelRts")).value.split("\n");

      if(values.length > 0) {
        let orders: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            orders.push({'trackingNumber': values[i]});
        }

        this.orders = orders;
      }
      
      this.bulkRtsOrder(this.orders, 0);
      // this.resetForm();
    
      // console.log(this.orders);
    }

    bulkRtsOrder(orders, index) {


        this.form.controls['tracking_number'].setValue(orders[index]['trackingNumber']);

         // this.buldAssignOrder(orders, index+1);

           this.apiService.outboundRtsComplete(this.form.getRawValue()).subscribe(data => {

                   // console.log(data.data);
              if (data.status) {

                // if('data' in data) {
                //   this.trips = data.data;

                //   if(this.trips.length > 0)
                //     this.form.controls['delivery_id'].setValue(this.trips[0]['id']);
                // }

                if((index+1) < orders.length) {
                      this.bulkRtsOrder(orders, index+1);  
                }
                else {
                  this.resetRtsForm();
                  // this.trips = null;
                }
              }  
              else {
                // this.resetForm();
                // this.failedOrders = [];
                this.failedOrders.push(orders[index]['trackingNumber']+' | '+data.message);

                this.bulkRtsOrder(orders, index+1);
                // alert(data.message);
              }
            });
    }

    onCompanyChange(event) {
        
        this.staff = null;
        this.form.controls['staff_id'].setValue('');
        this.company = this.options.companies.find(x => x.id == event.target.value);
    }

    onStaffChange(event) {
        
        this.staff = this.company.staffs.find(x => x.id == event.target.value);
    }
}
