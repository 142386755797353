import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
// const EXCEL_EXTENSION = '.xlsx';
const EXCEL_EXTENSION = '.csv';

// @Injectable()
export class ExcelService {

	audio2:any;

	constructor() { 
		this.audio2 = new Audio('assets/files/report_exported.wav');
        this.audio2.load();
	}

	public exportAsExcelFile(orders: any[], excelFileName: string): void {

	  // const personalWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(personal);
	  // const corporateWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(corporate);

	  const statusWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(orders);


	  const workbook: XLSX.WorkBook = { Sheets: { 'orders': statusWorksheet }, SheetNames: ['orders'] };


	  // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
	  const excelBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });

	  this.saveAsExcelFile(excelBuffer, excelFileName);
	}

	private saveAsExcelFile(buffer: any, fileName: string): void {
	   const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
	   FileSaver.saveAs(data, fileName + '_' + new  Date().toISOString().slice(0,10)  + EXCEL_EXTENSION);

	   this.audio2.play();
	}

	public importFromFile(bstr: string): XLSX.AOA2SheetOpts {
	    /* read workbook */
	    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

	    /* grab first sheet */
	    const wsname: string = wb.SheetNames[0];
	    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

	    /* save data */
	    const data = <XLSX.AOA2SheetOpts>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

	    return data;
	  }
}
