import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } 			from  '../api.service';

@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.css']
})
export class RsvpComponent implements OnInit {

  	data:any;
	code:any;
	host = 'https://api.urbandespatch.com';
	message:any;
	// host = 'http://localhost:8000';

    constructor(
    	private route: ActivatedRoute,
  		private apiService: ApiService,
		private router: Router,
	) { }


	ngOnInit() {

	  	this.route
			.queryParams
			.subscribe(params => {
				this.code = params['code'] || '';
				this.message = 'Loading...';
				console.log(this.code);
				// this.location.replaceState('/positions');

				this.index();
		});
	}

	index(): void {

		this.apiService.getRsvpDetails(this.code).subscribe(data => {

			if(data.status)
				this.data 		= data.data;
			else {
				window.location.href = 'https://www.urbandespatch.com';
			}
		});
	}

}
