import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';




@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  	user: any;
  	profile: any;
	org: any;
	token: string;
	options: any;
	cities: any;

	froalaOpts: any;


	form: FormGroup;
	uploadForm: FormGroup;
	formData: any;
	submitted = false;

	image: any;

	otherRace = false;


	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder,
		private fe: FroalaEditorModule
	) { }

	ngOnInit() {

		// this.getUser();

		this.form = this.fb.group({
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            gender: ['', [Validators.required]],
            hub_location_id: ['', [Validators.required]],
            address_1: ['', [Validators.required]],
            address_2: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state_id: ['', [Validators.required]],
            zipcode: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5)]],
            contact_number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
            contact_email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
			facebook_link: ['', []],

        });

        this.profile = this.form.value;

        this.uploadForm = this.fb.group({
	      profile: ['']
	    });

	    this.froalaOpts = {
		    charCounterMax: 3500,
		};

		this.index();

		// this.setNationalityValidators();
	}

	get f() { return this.form.controls; }

	

	// getUser(): void {

	// 	if (localStorage.getItem('user') !== null) {
	// 		this.token = JSON.parse(localStorage.getItem('user')).token;

	// 		if (!this.token) {
	// 			this.router.navigate(['/']);
	// 		}

			// this.index();
	// 	} else {
	// 		this.router.navigate(['/']);
	// 	}

	// }

	index(): void {

		this.apiService.userProfileOptions().subscribe(data => {

			this.options 		= data.data;

			// this.userProfileImage();
			this.userProfile();
		});
	}

	// onChange(value): void {
	//     if (value == '') {
	// 		this.cities = [];
	// 		this.form.controls['city'].setValue('');
	// 	} else {
	// 		this.cities = [];
	// 		this.form.controls['city'].setValue('');
	//     	this.getCitiesOfState(value);
	// 	}
	// }

	// race(value): void {
	// 	if (value == 7) { // 7 == Others
	// 		this.form.get('race_id').clearValidators();
	// 		this.form.controls['race_id'].setValue('');
	// 		this.form.get('race').setValidators([Validators.required]);
	// 		this.otherRace = true;
	// 	}
	// }

	closeOthers(field): void {
		if (field == 'race') {
			this.form.get('race').clearValidators();
			this.form.controls['race'].setValue('');
			this.form.get('race_id').setValidators([Validators.required]);
			this.otherRace = false;
		}
	}

	// getCitiesOfState(stateId): void {

	// 	this.cities = (this.options.states.filter(state => state.name == stateId))[0].cities;
	// }

	userProfile(): void {

		this.apiService.userProfile().subscribe(data => {

			this.user   			= data.data.user;
			this.profile 			= data.data.profile;
			this.org 				= data.data.organisation;

			this.form.setValue({

				first_name: this.profile.first_name,
				last_name: this.profile.last_name,
				gender : this.profile.gender,
				hub_location_id : this.profile.hub_location_id,
				address_1 : this.profile.address_1,
				address_2 : this.profile.address_2,
				city : this.profile.city,
				state_id : this.profile.state_id,
				zipcode : this.profile.zipcode,
				contact_number : this.profile.contact_number,
				contact_email : this.profile.contact_email,
				facebook_link : this.profile.facebook_link,
			});
		});
	}

	userProfileUpdate(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.userProfileUpdate(this.form.value).subscribe(data => {

				this.profile 		= data.data;

				this.submitted = false;
				window.scroll(0, 0);
				alert(data.message);

				// this.closeOthers('race');
				this.index();
			});
		} else {
			window.scroll(0, 0);
		}
	}

	uploadFile(event): void {
	    const elem = event.target;
	    if (elem.files.length > 0) {
	      this.image = elem.files[0];

	      const formData: FormData = new FormData();
    	  formData.append('file', elem.files[0], elem.files[0].name);

	      this.apiService.userProfileUploadImage(formData).subscribe(data => {

				if (!data['status']) {
					alert(data['message']);
				}
			});
	    }
	}

	userProfileImage(): void {

		this.apiService.userProfileImage().subscribe(data => {

				this.image = data;
			});
	}






	// uploadFiles(files:File[]) : Subscription {
	//     const config = new HttpRequest('POST', this.postUrl, this.myFormData), {
	//       reportProgress: true
	//     })

	//     return this.HttpClient.request( config )
	//     .subscribe(event=>{
	//       this.httpEvent = event

	//       if (event instanceof HttpResponse) {
	//         alert('upload complete, old school alert used')
	//       }
	//     },
	//     error=>{
	//       alert('!failure beyond compare cause:' + error.toString())
	//     })
	//   }

}
