import { Component, OnInit } from '@angular/core';
import { ApiService } 					from  '../../api.service';

@Component({
  selector: 'app-b2b-jobs',
  templateUrl: './b2b-jobs.component.html',
  styleUrls: ['./b2b-jobs.component.css']
})
export class B2bJobsComponent implements OnInit {

  type = '1';
  jobs:any[];
  clients:any;
  vendors:any;
  customerFilter = '';
  vendorFilter = '';
  searchSo = '';
  sortBy = 'DESC';
  sortColumn = 'order_number'

  constructor(private apiService: ApiService) { }

  ngOnInit() {
  	this.index()
  }

  index() {

    let param = {
      'vendor_id':this.vendorFilter,
      'client_id':this.customerFilter,
      'search_so':this.searchSo,
      'sortColumn': this.sortColumn,
      'sortBy': this.sortBy
    }
  	this.apiService.businessJobs(param).subscribe(data => {

      this.clients   = data.data.clients
      this.vendors   = data.data.vendors
  		this.jobs 		 = data.data.jobs;
  	});
  }

  clearFilter(type) {

    if(type === 'customer')
      this.customerFilter = ''
    else if(type === 'vendor')
      this.vendorFilter = ''
    else if(type === 'so')
      this.searchSo = ''

    this.index();
  }


  sort(columnName) {
      
    // if(this.sortColumn === columnName)
      if(this.sortBy == 'ASC')
        this.sortBy = 'DESC'
      else 
        this.sortBy = 'ASC'

    this.sortColumn = columnName
    this.index()
  }

  getTitle(columnName) {
    if(this.sortBy == 'ASC')
      return 'Sort descending'
    else
      return 'Sort ascending'
  }
}
