import { Component, OnInit } from '@angular/core';
import { Observable, interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-lazada-dashboard',
  templateUrl: './lazada-dashboard.component.html',
  styleUrls: ['./lazada-dashboard.component.css']
})
export class LazadaDashboardComponent implements OnInit {

	private updateSubscription: Subscription;

	secs = 0;

  constructor() { }

  ngOnInit() {
  	this.updateSubscription = interval(60000).subscribe(
        (val) => { this.updateStats(val)}
  	);

  }

  ngOnDestroy() {
      this.updateSubscription.unsubscribe();
  }


  private updateStats(val) {
      // this.index();
  }

}
