import { Component, OnInit } from '@angular/core';
import { NgForm } 						from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } 					from  '../api.service';
import { ExcelService }                       from '../excel.service';

@Component({
  selector: 'app-hub-locations',
  templateUrl: './hub-locations.component.html',
  styleUrls: ['./hub-locations.component.css'],
  providers: [ExcelService]
})
export class HubLocationsComponent implements OnInit {

	form: FormGroup;
  editForm: FormGroup;
	type = 'add-hub';
	submitted = false;
  editSubmitted = false;
	options: any;
  hubs: any;
  clonedHubs: any;
  hubActive = 'all';
  hub: any;
  editRole = false;
  editIndex: any;
  roles: any;
  exportData: Array<Object> = [];
  collapsed = true;


  constructor(
  	private fb: FormBuilder,
  	private apiService: ApiService,
    private excelService: ExcelService,
  	) { }

  ngOnInit() {
  	this.form = this.fb.group({
        name: ['', 	[Validators.required, Validators.minLength(8)]],
        company_id: ['', 	[Validators.required]],
        code: ['', [Validators.required, Validators.maxLength(5)]],
        city: ['', 	[Validators.required]],
        state: ['', 	[Validators.required]],
        picName: ['',   [Validators.required]],
        picPhone: ['',   [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
        transit_hub: [true, 	[Validators.required]],
        is_3pl: [false, 	[Validators.required]],
        is_client: [false, 	[Validators.required]],
        client_slug: [null, 	[]],
    });

    this.editForm = this.fb.group({
        id: ['',   [Validators.required]],
        name: ['',   [Validators.required, Validators.minLength(8)]],
        company_id: ['',   [Validators.required]],
        code: ['', [Validators.required, Validators.maxLength(5)]],
        city: ['',   [Validators.required]],
        state: ['',   [Validators.required]],
        picName: ['',   [Validators.required]],
        picPhone: ['',   [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
        transit_hub: [true,   [Validators.required]],
        is_3pl: [false,   [Validators.required]],
        is_client: [false,   [Validators.required]],
        client_slug: [null,   []],
    });

    this.index();
  }

  get f() { return this.form.controls; }
  get ef() { return this.editForm.controls; }

  index() {
		
	    this.apiService.hubOptions().subscribe(data => {

    		if(data.status) {

            	this.options     = data.data;
            	// console.log(this.shuntOptions);
    		}
        });
  	}

  showView(type) {


  	this.type = type;
  }


  create() {

  	this.submitted = true;

  	if (this.form.valid) {
		this.apiService.hubCreate(this.form.value).subscribe(data => {

			if (data.status == true) {
				this.resetForm();
				this.index();
			}

			this.submitted = false;
			alert(data.message);
		});
	}
  }

  resetForm() {

  	this.form.controls['name'].setValue('');
  	this.form.controls['company_id'].setValue('');
  	this.form.controls['code'].setValue('');
  	this.form.controls['city'].setValue('');
  	this.form.controls['state'].setValue('');
    this.form.controls['picName'].setValue('');
    this.form.controls['picPhone'].setValue('');
  	this.form.controls['transit_hub'].setValue(true);
  	this.form.controls['is_3pl'].setValue(false);
  	this.form.controls['is_client'].setValue(false);
  }

  getHubs() {

        this.apiService.hubGetAll().subscribe(data => {

            this.hubs             = data.data.hubs;
            this.clonedHubs       = JSON.parse(JSON.stringify(data));
            this.roles            = data.data.roles;

            this.filterList(null, 'active');
        });
  }

  toggleHubActive(id) {

    this.apiService.hubToggleActive(id).subscribe(data => {

            if(data.status)
              this.getHubs();
            // else
              alert(data.message);
        });  
  }

  filterList(evt, type) {

    if(type === 'active') {

      let active = 1;

      this.hubs = JSON.parse(JSON.stringify(this.clonedHubs)).data.hubs;
      
      if(this.hubActive === 'active')
        active = 1;
      else if(this.hubActive === 'inactive')
        active = 0;

      this.hubs = this.hubs.filter(item => {

        if (this.hubActive !== 'all') {
          // return item.tracking.includes(searchTerm);
          return item.active === active;
        }
        else
          return item;

      });

    }
  }    
  
  loadHub(hub) {

      this.editSubmitted = false;
      this.editRole = false;

      this.hub = hub;

      this.editForm.controls['id'].setValue(hub.id);
      this.editForm.controls['name'].setValue(hub.name);
      this.editForm.controls['company_id'].setValue(hub.company_id);
      this.editForm.controls['code'].setValue(hub.code);
      this.editForm.controls['city'].setValue(hub.city);
      this.editForm.controls['state'].setValue(hub.state);
      this.editForm.controls['picName'].setValue(hub.picName);
      this.editForm.controls['picPhone'].setValue(hub.picPhone);
      this.editForm.controls['transit_hub'].setValue(hub.transit_hub);
      this.editForm.controls['is_3pl'].setValue(hub.is_3pl);
      this.editForm.controls['is_client'].setValue(hub.is_client);
      this.editForm.controls['client_slug'].setValue(hub.client_slug);

      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  updateHub(hub) {

    // if(this.hub) { // PERFORM UPDATE
      // console.log(this.hub);
      this.editSubmitted = true;

      if(this.editForm.valid) {
        this.apiService.hubUpdate(this.editForm.value).subscribe(data => {

            this.editSubmitted = false;

            if(data.status) {
              this.getHubs();
              this.cancelUpdate();
            }
            
            alert(data.message);
        });  
      }
  }

  cancelUpdate() {
    this.hub = null;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.editSubmitted = false;
  }

  activateUser(user, index) {

    this.apiService.hubUserActivate(user.id).subscribe(data => {

        if(data.status) {
          this.hub.users[index]['activations'].push(data.data)
        }

        alert(data.message);
    });  
  }

  toggleBan(user, index) {
    this.apiService.hubUserToggleBan(user.id).subscribe(data => {

        if(data.status) {
          this.hub.users[index]['banned'] = data.data;
        }

        alert(data.message);
    });  

  }

  selectRole(user, index) {
    this.editRole = true;
    this.editIndex = index;
  }

  updateRole(event, user, index) {

    // console.log(user);
    // console.log(index);
    // console.log(event.target.value);
    let param = {
      'user_id':  user.id,
      'role_name':  event.target.value
    }

    this.apiService.hubUserUpdateRole(param).subscribe(data => {

            // this.editSubmitted = false;

            if(data.status) {
              this.editRole = false;
              this.hub.users[index]['roles'][0] = data.data;

              // this.getHubs();
              // this.cancelUpdate();
            }
            
            alert(data.message);
        });  
  }

  exportHubs() {

      if(this.hubs.length == 0)
        alert('No hubs found to export.');
      else {

        for (var i = 0; i < this.hubs.length; i++) {
          let param = {


            'Name': this.hubs[i].name,
            'Code': this.hubs[i].code,
            'City': this.hubs[i].city,
            'State': this.hubs[i].state,
            'Managed By': this.hubs[i].company ? this.hubs[i].company.ssm_name: 'No company set',
            'PIC Name': this.hubs[i].picName,
            'PIC Phone': this.hubs[i].picPhone,
            'Total Users': this.hubs[i].users.length,
            'Transit Hub': this.hubs[i].transit_hub,
            'Is 3PL': this.hubs[i].is_3pl,
            'Is Client': this.hubs[i].is_client,
            'Client Slug': this.hubs[i].client_slug,
            'Active': this.hubs[i].active,
            'Created At': this.hubs[i].created_at,
            'Created By': this.hubs[i].added_by_user ? this.hubs[i].added_by_user.first_name: 'No creator set',
            'Updated At': this.hubs[i].updated_at
          }

          this.exportData.push(param)
        }

        this.excelService.exportAsExcelFile(this.exportData, 'at_hubs_report');
        }
    }
}
