import { Component, OnInit } 				from '@angular/core';
import { NgForm } 							from '@angular/forms';
import { ApiService } 						from  '../api.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

	form: FormGroup;
	submitted = false;


	constructor(
		private apiService: ApiService,
		private fb: FormBuilder,
	) { }

	ngOnInit() {
		this.form = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			name: ['', [Validators.required]],
			message: ['', [Validators.required]],
		});

	}

	get f() { return this.form.controls; }

	scroll(): void {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	}
}
