import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } 					from  '../../../api.service';
import { Router} 						from '@angular/router';
import { ActivatedRoute} 				from '@angular/router';

@Component({
  selector: 'app-lazada-order-timeline',
  templateUrl: './lazada-order-timeline.component.html',
  styleUrls: ['./lazada-order-timeline.component.css']
})
export class LazadaOrderTimelineComponent implements OnInit {

	data:any;
	code:any;

    constructor(
    	private route: ActivatedRoute,
  		private apiService: ApiService,
		private router: Router,
	) { }


	ngOnInit() {

		window.scroll(0,0);
		this.code = this.route.snapshot.paramMap.get('code');
	  	this.index();
	}

	index(): void {
		this.apiService.lazadaPackageTimeline(this.code).subscribe(data => {

			this.data 		= data.data;

			if(!data.data)
				this.router.navigate(['/orders']);
		});
	}
}