import { Component, OnInit } from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

@Component({
  selector: 'app-profile-sidebar',
  templateUrl: './profile-sidebar.component.html',
  styleUrls: ['./profile-sidebar.component.css']
})
export class ProfileSidebarComponent implements OnInit {

  company: any;
  user: any;


  constructor(
  		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location
	) { }

  ngOnInit() {
    window.scroll(0, 0);
    // this.getUser();
  }

 //  logout(): void {

 //    localStorage.removeItem('user');
	// 	localStorage.removeItem('user.company');
	// 	this.router.navigate(['/positions']);
	// }

  getUser(): void {

    if (localStorage.getItem('user') !== null) {
      this.user = JSON.parse(localStorage.getItem('user'));

      if (!this.user) {
        this.router.navigate(['/positions']);
      }

      if (localStorage.getItem('user.company') !== null) {
        this.company = JSON.parse(localStorage.getItem('user.company'));
      }
    } else {
      alert('Please login before posting a job.');
      this.router.navigate(['/positions']);
    }

  }
}
