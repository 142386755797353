import { Component, OnInit } 					from '@angular/core';
import { FormBuilder, FormGroup, Validators } 	from '@angular/forms';
import { Subscription } 						from 'rxjs';
import { ApiService } 							from  '../../../api.service';
import { Router} 								from '@angular/router';
import { ExcelService }                   		from '../../../excel.service';

import { saveAs } from 'file-saver';

@Component({
  selector: 'app-lazada-status',
  templateUrl: './lazada-status.component.html',
  styleUrls: ['./lazada-status.component.css'],
  providers: [ExcelService]
})
export class LazadaStatusComponent implements OnInit {

  	reportForm: FormGroup;

	submitted = false;

	constructor(
		private apiService: ApiService,
		private router: Router,
		private fb: FormBuilder,
		private excelService: ExcelService
		) { }

	get rf() { return this.reportForm.controls; }

	ngOnInit() {

		this.reportForm = this.fb.group({
			received_from: 	['', [Validators.required]],
			received_until: ['', [Validators.required]],
		});

        this.setTodayDatePicker();
	}

	generate(): void {

		this.submitted = true;

		if(this.reportForm.valid) {
			this.apiService.lazadaStatusReport(this.reportForm.value).subscribe(data => {

                if (data.status) {
                    if(data.data.length !== 0)
                        this.excelService.exportAsExcelFile(data.data, 'at_lazada_status_report');
                    else
                        alert('No orders found to export.');
                } else {
                    alert(data.message);
                }
            });

            this.submitted = false;
		}
	}

    setTodayDatePicker(): void {

    	var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        this.reportForm.controls['received_until'].setValue((new Date(Date.now() - tzoffset)).toISOString().slice(0, 10));
    }
}
