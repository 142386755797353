import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } 					from  '../../api.service';
import { Router} 						from '@angular/router';
import { ExcelService }                       from '../../excel.service';
import { DOCUMENT } from '@angular/common'; 

declare const google;

@Component({
	selector: 'app-add-new',
	templateUrl: './add-new.component.html',
	styleUrls: ['./add-new.component.css'],
	providers: [ExcelService]
})
export class AddNewComponent implements OnInit {

	type: any = '';
	viewType: any = 'add-new';
	options: any;

	companyForm: FormGroup;
	companyEditForm: FormGroup;
	staffForm: FormGroup;
	staffEditForm: FormGroup;
	transportForm: FormGroup;
	transportEditForm: FormGroup;
	clientForm: FormGroup;
	clientEditForm: FormGroup;

	submitted = false;
	collapsed = true;
	
	searchTerm = '';
	company_id = '';
	
	companies: any;
	company: any;
	states: any;

	staffs: any;
	staff: any;
	resetPassword: any;
	resetIndex: any;
	resetType = '';
	resetOther = '';
	
	transports: any;
	transport: any;

	clients: any;
	client: any;


	staffActive = '';

	editSubmitted = false;
	companyEditSubmitted = false;
	staffEditSubmitted = false;
	transportEditSubmitted = false;
	clientEditSubmitted = false;

	autoCompleteOptions = {
	    fields: ["address_components", "geometry", "name", "formatted_address"],
	    // types: ["address"],
	    componentRestrictions: { country: 'MY' }
	  }

	constructor(
		private apiService: ApiService,
		private router: Router,
		private fb: FormBuilder,
		private excelService: ExcelService,
		@Inject(DOCUMENT) document: Document
	) { 
        // this.initMap();
	}

	ngOnInit() {
		this.companyForm = this.fb.group({
            ssm_name: ['', [Validators.required]],
            ssm_number: ['', [Validators.required]],
            ssm_expiry: ['', [Validators.required]],
            sst_number: ['', []],
            ssm_owner_name: ['', [Validators.required]],
			address_1: ['', [Validators.required]],
            address_2: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state_id: ['', [Validators.required]],
            zipcode: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5)]],
            contact_number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
            contact_email: ['', [Validators.required, Validators.email]],
            bank_name: ['', [Validators.required]],
            account_name: ['', [Validators.required]],
            account_number: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            remarks: ['', []],
            document_url: ['', [Validators.required]],
        });

        this.companyEditForm = this.fb.group({
            id: ['', 		[Validators.required]],
            ssm_name: ['', [Validators.required]],
            ssm_number: ['', [Validators.required]],
            ssm_expiry: ['', [Validators.required]],
            sst_number: ['', []],
            ssm_owner_name: ['', [Validators.required]],
			address_1: ['', [Validators.required]],
            address_2: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state_id: ['', [Validators.required]],
            zipcode: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5)]],
            contact_number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
            contact_email: ['', [Validators.required, Validators.email]],
            bank_name: ['', [Validators.required]],
            account_name: ['', [Validators.required]],
            account_number: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            remarks: ['', []],
            document_url: ['', [Validators.required]],
        });

        this.staffForm = this.fb.group({
        	company_id: ['', [Validators.required]],
        	role: ['', [Validators.required]],
        	full_name: ['', [Validators.required]],
        	nric_number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(12)]],
        	username: ['', [Validators.required, Validators.minLength(6)]],
        	password: ['', [Validators.required]],
        	contact_number: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        	licence_expiry: ['', [Validators.required]],
        	remarks: ['', []],
        	document_url: ['', [Validators.required]],
        });

        this.staffEditForm = this.fb.group({
        	id: ['', [Validators.required]],
        	company_id: ['', [Validators.required]],
        	role: ['', [Validators.required]],
        	full_name: ['', [Validators.required]],
        	nric_number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(12)]],
        	username: ['', [Validators.required, Validators.minLength(6)]],
        	password: ['', []],
        	contact_number: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        	licence_expiry: ['', [Validators.required]],
        	remarks: ['', []],
        	document_url: ['', [Validators.required]],
        });

        this.transportForm = this.fb.group({
        	company_id: ['', [Validators.required]],
        	type_id: ['', [Validators.required]],
        	plate_number: ['', [Validators.required]],
        	year_made: ['', []],
        	insurance_expiry: ['', [Validators.required]],
        	roadtax_expiry: ['', [Validators.required]],
        	remarks: ['', []],
        	document_url: ['', [Validators.required]],
        });

        this.transportEditForm = this.fb.group({
        	id: ['', [Validators.required]],
        	company_id: ['', [Validators.required]],
        	type_id: ['', [Validators.required]],
        	plate_number: ['', [Validators.required]],
        	year_made: ['', []],
        	insurance_expiry: ['', [Validators.required]],
        	roadtax_expiry: ['', [Validators.required]],
        	remarks: ['', []],
        	document_url: ['', [Validators.required]],
        });

        this.clientForm = this.fb.group({
            ssm_name: ['', [Validators.required]],
            ssm_number: ['', [Validators.required]],
			full_address: ['', [Validators.required]],
			address_1: ['', [Validators.required]],
            address_2: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state_id: ['', [Validators.required]],
            lat: ['', [Validators.required]],
            long: ['', [Validators.required]],
            zipcode: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5)]],
            contact_number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
            contact_email: ['', [Validators.required, Validators.email]],
            contact_name: ['', [Validators.required]],
            remarks: ['', [Validators.required]],
            document_url: ['', [Validators.required]],
        });

        this.clientEditForm = this.fb.group({
        	id: ['', [Validators.required]],
            ssm_name: ['', [Validators.required]],
            ssm_number: ['', [Validators.required]],
			full_address: ['', [Validators.required]],
			address_1: ['', [Validators.required]],
            address_2: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state_id: ['', [Validators.required]],
            lat: ['', [Validators.required]],
            long: ['', [Validators.required]],
            zipcode: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5)]],
            contact_number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
            contact_email: ['', [Validators.required, Validators.email]],
            contact_name: ['', [Validators.required]],
            remarks: ['', [Validators.required]],
            document_url: ['', [Validators.required]],
        });

        this.initMap();

	}

	get cf() { return this.companyForm.controls; }
	get cef() { return this.companyEditForm.controls; }
	get sf() { return this.staffForm.controls; }
	get sef() { return this.staffEditForm.controls; }
	get tf() { return this.transportForm.controls; }
	get tef() { return this.transportEditForm.controls; }
	get clf() { return this.clientForm.controls; }
	get clef() { return this.clientEditForm.controls; }

	onChange(value): void {
		this.type = value;
		window.scroll(0,0);
		this.submitted = false;
		this.reset('');
		this.index(value);
	}

	index(type): void {

		if(type === 'company') {
			this.apiService.companyOptions().subscribe(data => {

				this.options 		= data.data;
			});
		}
		else if(type === 'staff') {
			this.apiService.staffOptions().subscribe(data => {

				this.options 		= data.data;
			});
		}
		else if(type === 'transport') {
			this.apiService.transportOptions().subscribe(data => {

				this.options 		= data.data;
			});
		}
		else if(type === 'client') {
			this.apiService.clientOptions().subscribe(data => {

				this.options 		= data.data;
			});
		}
	}

	create(type): void {

		this.submitted = true;

		if(type === 'company') {
			if (this.companyForm.valid) {
				this.apiService.companyCreate(this.companyForm.value).subscribe(data => {

					if (data.status)
						this.reset('company');						
					
					alert(data.message);
				});
			}
		}
		else if(type === 'staff') {
			if (this.staffForm.valid) {
				this.apiService.staffCreate(this.staffForm.value).subscribe(data => {

					if (data.status)
						this.reset('staff');			
					
					alert(data.message);
				});
			}

		}
		else if(type === 'transport') {
			if (this.transportForm.valid) {
				this.apiService.transportCreate(this.transportForm.value).subscribe(data => {

					if (data.status)
						this.reset('transport');			
					
					alert(data.message);
				});
			}
		}
		else if(type === 'client') {
			if (this.clientForm.valid) {
				this.apiService.clientCreate(this.clientForm.value).subscribe(data => {

					if (data.status)
						this.reset('client');			
					
					alert(data.message);
				});
			}
		}
	}

	reset(type): void {

		if(type === 'company')
			this.companyForm.reset();
		else if(type === 'staff')
			this.staffForm.reset();
		else if(type === 'transport')
			this.transportForm.reset();
		else if(type === 'client')
			this.clientForm.reset();
		else {
			this.companyForm.reset();
			this.staffForm.reset();
			this.transportForm.reset();
			this.clientForm.reset();
		}

		window.scroll(0,0);
		this.submitted = false;
	}

	searchCompany() {

		if(this.searchTerm === '')
			this.clearSearch();

		else {

			let param = {
				'search_type': 'ssm_name',
				'search_value': this.searchTerm
			}

			this.apiService.companySearch(param).subscribe(data => {

				if (data.status) {
					this.companies = data.data.companies;
					this.states = data.data.states;
				}
				else
					alert(data.message);
			});
		}
	}

	clearSearch() {
		this.searchTerm = '';
		this.companies = null;
		this.staffs = null;
		this.transports = null;
		this.company = null;
		this.staff = null;
		this.transport = null;
		this.clients = null;
		this.client = null;
	}

	loadCompany(company) {

      this.companyEditSubmitted = false;
      // this.editRole = false;

      this.company = company;

      this.companyEditForm.controls['id'].setValue(company.id);
      this.companyEditForm.controls['ssm_name'].setValue(company.ssm_name);
      this.companyEditForm.controls['ssm_number'].setValue(company.ssm_number);
      this.companyEditForm.controls['ssm_expiry'].setValue(company.ssm_expiry);
      this.companyEditForm.controls['sst_number'].setValue(company.sst_number);
      this.companyEditForm.controls['ssm_owner_name'].setValue(company.ssm_owner_name);
      this.companyEditForm.controls['address_1'].setValue(company.address_1);
      this.companyEditForm.controls['address_2'].setValue(company.address_2);
      this.companyEditForm.controls['city'].setValue(company.city);
      this.companyEditForm.controls['state_id'].setValue(company.state_id);
      this.companyEditForm.controls['zipcode'].setValue(company.zipcode);
      this.companyEditForm.controls['contact_number'].setValue(company.contact_number);
      this.companyEditForm.controls['contact_email'].setValue(company.contact_email);
      this.companyEditForm.controls['bank_name'].setValue(company.bank_name);
      this.companyEditForm.controls['account_name'].setValue(company.account_name);
      this.companyEditForm.controls['account_number'].setValue(company.account_number);
      this.companyEditForm.controls['remarks'].setValue(company.remarks);
      this.companyEditForm.controls['document_url'].setValue(company.document_url);

      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  	}

  	updateCompany() {

    // if(this.hub) { // PERFORM UPDATE
      this.companyEditSubmitted = true;

      if(this.companyEditForm.valid) {
        this.apiService.companyUpdate(this.companyEditForm.value).subscribe(data => {

            this.companyEditSubmitted = false;

            if(data.status) {
              this.searchCompany();
              this.cancelUpdate();
            }
            
            alert(data.message);
        });  
      }
  	}
  	
  	cancelUpdate() {
	    this.company = null;
	    this.staff = null;
	    this.transport = null;
	    this.client = null;
	    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	    this.companyEditSubmitted = false;
	    this.staffEditSubmitted = false;
	    this.transportEditSubmitted = false;
	    this.clientEditSubmitted = false;
	}

	exportCompanies() {

		this.apiService.companyExport().subscribe(data => {

				if (data.status) {
					// this.companies = ;
					this.excelService.exportAsExcelFile(data.data, 'at_companies_report');
					// this.states = data.data.states;
				}
				else
					alert(data.message);
			});
	}



	searchStaff() {

		if(this.searchTerm === '')
			this.clearSearch();

		else {

			let param = {
				// 'search_type': 'ssm_name',
				'search_value': this.searchTerm,
				'active_value': this.staffActive, 
				'company_id': this.company_id, 

			}

			this.apiService.staffSearch(param).subscribe(data => {

				if (data.status) {
					this.staffs = data.data.staffs;
				}
				else
					alert(data.message);
			});
		}
	}

	toggleStaffActive(id) {

	    this.apiService.staffToggleActive(id).subscribe(data => {

	            if(data.status)
	              this.searchStaff();
	            // else
	              alert(data.message);
	        });  
	  }

	selectPasswordGenerator(staff, index) {
	    this.resetPassword = true;
	    this.resetIndex = index;
	  }

	loadStaff(staff) {

		this.staffEditSubmitted = false;
      	// this.editRole = false;

      	this.staff = staff;

      	this.staffEditForm.controls['id'].setValue(staff.id);
      	this.staffEditForm.controls['company_id'].setValue(staff.company_id);
      	this.staffEditForm.controls['role'].setValue(staff.role);
      	this.staffEditForm.controls['full_name'].setValue(staff.full_name);
      	this.staffEditForm.controls['nric_number'].setValue(staff.nric_number);
      	this.staffEditForm.controls['contact_number'].setValue(staff.contact_number);
      	this.staffEditForm.controls['licence_expiry'].setValue(staff.licence_expiry);
      	this.staffEditForm.controls['remarks'].setValue(staff.remarks);
      	this.staffEditForm.controls['username'].setValue(staff.username);
      	this.staffEditForm.controls['document_url'].setValue(staff.document_url);

		window.scroll({ top: 0, left: 0, behavior: 'smooth' });      	
	}

	updateStaff() {

    // if(this.hub) { // PERFORM UPDATE
      this.staffEditSubmitted = true;

      if(this.staffEditForm.valid) {
        this.apiService.staffUpdate(this.staffEditForm.value).subscribe(data => {

            this.staffEditSubmitted = false;

            if(data.status) {
              this.searchStaff();
              this.cancelUpdate();
            }
            
            alert(data.message);
        });  
      }
  	}

  	exportStaffs() {

		this.apiService.staffExport().subscribe(data => {

			if (data.status) {
				// this.companies = ;
				this.excelService.exportAsExcelFile(data.data, 'at_staffs_report');
				// this.states = data.data.states;
			}
			else
				alert(data.message);
		});
	}

	cancelResetPassword() {

		this.resetPassword = false; 
		this.resetType = ''
	}

	resetStaffPassword(staff, index) {

		let param = {
			'reset_type': this.resetType,
			'reset_other': this.resetOther,
			'staff_id': staff.id
		}

		if(this.resetType === 'other' && this.resetOther === '')
			alert('New password cannot be empty');
		else
			this.apiService.staffResetPassword(param).subscribe(data => {

	            this.staffEditSubmitted = false;

	            if(data.status) {
	              this.searchStaff();
	              this.cancelResetPassword();
	            }
	            
	            alert(data.message);
	        }); 
	}

	searchTransport() {

		if(this.searchTerm === '')
			this.clearSearch();

		else {

			let param = {
				// 'search_type': 'ssm_name',
				'search_value': this.searchTerm,
				// 'active_value': this.staffActive, 
				'company_id': this.company_id, 

			}

			this.apiService.transportSearch(param).subscribe(data => {

				if (data.status) {
					this.transports = data.data.transports;
				}
				else
					alert(data.message);
			});
		}
	}

	loadTransport(transport) {

		this.transportEditSubmitted = false;

      	this.transport = transport;

      	this.transportEditForm.controls['id'].setValue(transport.id);
      	this.transportEditForm.controls['company_id'].setValue(transport.company_id);
      	this.transportEditForm.controls['type_id'].setValue(transport.type_id);
      	this.transportEditForm.controls['plate_number'].setValue(transport.plate_number);
      	this.transportEditForm.controls['year_made'].setValue(transport.year_made);
      	this.transportEditForm.controls['insurance_expiry'].setValue(transport.insurance_expiry);
      	this.transportEditForm.controls['roadtax_expiry'].setValue(transport.roadtax_expiry);
      	this.transportEditForm.controls['remarks'].setValue(transport.remarks);
      	this.transportEditForm.controls['document_url'].setValue(transport.document_url);

		window.scroll({ top: 0, left: 0, behavior: 'smooth' });    
	}

	updateTransport() {

    // if(this.hub) { // PERFORM UPDATE
      this.transportEditSubmitted = true;

      if(this.transportEditForm.valid) {
        this.apiService.transportUpdate(this.transportEditForm.value).subscribe(data => {

            this.transportEditSubmitted = false;

            if(data.status) {
              this.searchTransport();
              this.cancelUpdate();
            }
            
            alert(data.message);
        });  
      }
  	}

  	exportTransports() {

		this.apiService.transportExport().subscribe(data => {

			if (data.status) {
				// this.companies = ;
				this.excelService.exportAsExcelFile(data.data, 'at_transports_report');
				// this.states = data.data.states;
			}
			else
				alert(data.message);
		});
	}





	searchClient() {

		if(this.searchTerm === '')
			this.clearSearch();

		else {

			let param = {
				'search_type': 'ssm_name',
				'search_value': this.searchTerm
			}

			this.apiService.clientSearch(param).subscribe(data => {

				if (data.status) {
					this.clients = data.data.clients;
					this.states = data.data.states;
				}
				else
					alert(data.message);
			});
		}
	}

	loadClient(client) {

      this.clientEditSubmitted = false;
      // this.editRole = false;

      this.client = client;

      this.clientEditForm.controls['id'].setValue(client.id);
      this.clientEditForm.controls['state_id'].setValue(client.state_id);
      this.clientEditForm.controls['ssm_name'].setValue(client.ssm_name);
      this.clientEditForm.controls['ssm_number'].setValue(client.ssm_number);
      this.clientEditForm.controls['full_address'].setValue(client.full_address);
      this.clientEditForm.controls['address_1'].setValue(client.address_1);
      this.clientEditForm.controls['address_2'].setValue(client.address_2);
      this.clientEditForm.controls['city'].setValue(client.city);
      this.clientEditForm.controls['lat'].setValue(client.lat);
      this.clientEditForm.controls['long'].setValue(client.long);
      this.clientEditForm.controls['zipcode'].setValue(client.zipcode);
      this.clientEditForm.controls['contact_name'].setValue(client.contact_name);
      this.clientEditForm.controls['contact_number'].setValue(client.contact_number);
      this.clientEditForm.controls['contact_email'].setValue(client.contact_email);
      this.clientEditForm.controls['remarks'].setValue(client.remarks);
      this.clientEditForm.controls['document_url'].setValue(client.document_url);
      
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  	}

  	updateClient() {

    // if(this.hub) { // PERFORM UPDATE
      this.clientEditSubmitted = true;

      if(this.clientEditForm.valid) {
        this.apiService.clientUpdate(this.clientEditForm.value).subscribe(data => {

            this.clientEditSubmitted = false;

            if(data.status) {
              this.searchClient();
              this.cancelUpdate();
            }
            
            alert(data.message);
        });  
      }
  	}

	exportClients() {

		this.apiService.clientExport().subscribe(data => {

				if (data.status) {
					// this.companies = ;
					this.excelService.exportAsExcelFile(data.data, 'at_clients_report');
					// this.states = data.data.states;
				}
				else
					alert(data.message);
			});
	}








	initMap(): void {
	  	const CONFIGURATION = {
		    "ctaTitle": "Checkout",
		    "mapOptions": {
		    	"center": {
		    		"lat":3.138193683612903, 
		    		"lng":101.52129885694214
		    	},
		    	"fullscreenControl":true,
		    	"mapTypeControl":true,
		    	"streetViewControl":true,
		    	"zoom":11,
		    	"zoomControl":true,
		    	"maxZoom":22
		    },
		    "mapsApiKey": "AIzaSyCu2GggOU8uAiZIzRY9JIiTbp2EV3riyWA",
		    "capabilities": {
		    	"addressAutocompleteControl":true,
		    	"mapDisplayControl":true,
		    	"ctaControl":true
			}
  		};
	  
		
	  // let map = new google.maps.Map((document.getElementById("map") as HTMLElement), {
	  //   zoom: 				CONFIGURATION.mapOptions.zoom,
	  //   center: 			CONFIGURATION.mapOptions.center,
	  //   mapTypeControl: 	CONFIGURATION.mapOptions.mapTypeControl,
	  //   fullscreenControl: 	CONFIGURATION.mapOptions.fullscreenControl,
	  //   zoomControl: 		CONFIGURATION.mapOptions.zoomControl,
	  //   streetViewControl: 	CONFIGURATION.mapOptions.streetViewControl,
	  //   componentRestrictions: { country: 'MY' }
	  // });
	  
	  // const marker = new google.maps.Marker({map: map, draggable: false});
	  
	  const autocompleteInput = this.clientForm.controls['full_address'].value;
	  
	  const autocomplete = new google.maps.places.Autocomplete(autocompleteInput, {
	    fields: ["address_components", "geometry", "name"],
	    types: ["address"],
	    componentRestrictions: { country: 'MY' }
	  });
	  
	  // autocomplete.addListener('place_changed', function () {
	  //   marker.setVisible(false);
	  //   const place = autocomplete.getPlace();
	  //   if (!place.geometry) {
	  //     // User entered the name of a Place that was not suggested and
	  //     // pressed the Enter key, or the Place Details request failed.
	  //     window.alert('No details available for input: \'' + place.name + '\'');
	  //     return;
	  //   }
	  //   this.renderAddress(place, map, marker);
	  // });
	}

	renderAddress(place, map, marker) {
	    map.setCenter(place.geometry.location);
	    marker.setPosition(place.geometry.location);
	    marker.setVisible(true);
	  }

	fillInAddress(place) {  // optional parameter
    	this.clientForm.controls['full_address'].setValue(place.name + ', ' + place.formatted_address)
    	this.clientForm.controls['address_1'].setValue(this.getAddressComp(place, 'route'))
        this.clientForm.controls['address_2'].setValue(this.getAddressComp(place, 'sublocality_level_1'))
        this.clientForm.controls['city'].setValue(this.getAddressComp(place, 'locality'))
        
        this.clientForm.controls['state_id'].setValue(this.getStateIdFromName(this.getAddressComp(place, 'administrative_area_level_1')))
        this.clientForm.controls['zipcode'].setValue(this.getAddressComp(place, 'postal_code'))
        this.clientForm.controls['lat'].setValue(place.geometry.location.lat())
        this.clientForm.controls['long'].setValue(place.geometry.location.lng())
	}

	fillInAddressEdit(place) {  // optional parameter
    	this.clientEditForm.controls['full_address'].setValue(place.name + ', ' + place.formatted_address)
    	this.clientEditForm.controls['address_1'].setValue(this.getAddressComp(place, 'route'))
        this.clientEditForm.controls['address_2'].setValue(this.getAddressComp(place, 'sublocality_level_1'))
        this.clientEditForm.controls['city'].setValue(this.getAddressComp(place, 'locality'))
        
        this.clientEditForm.controls['state_id'].setValue(this.getStateIdFromName(this.getAddressComp(place, 'administrative_area_level_1')))
        this.clientEditForm.controls['zipcode'].setValue(this.getAddressComp(place, 'postal_code'))
        this.clientEditForm.controls['lat'].setValue(place.geometry.location.lat())
        this.clientEditForm.controls['long'].setValue(place.geometry.location.lng())
	}

	  getStateIdFromName(name) {

	  	let state = this.options.states.find(x => x.name == name);
	  	return state.id;
	  }

	  getAddressComp = function (place, type) {
	  	const addressNameFormat = {
	      'route'						: 'long_name',
	      'sublocality_level_1'			: 'long_name',
	      'locality'					: 'long_name',
	      'administrative_area_level_1'	: 'short_name',
	      'country'						: 'long_name',
	      'postal_code'					: 'short_name',
	    };
	      for (const component of place.address_components) {
	        if (component.types[0] === type) {
	          return component[addressNameFormat[type]];
	        }
	      }
	      return '';
	    };

	handleAddressChange(place:any) {
	    this.fillInAddress(place);
    }


}
