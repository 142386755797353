import { Component, OnInit } 	from '@angular/core';
import { Subscription } 		from 'rxjs';
import { ApiService } 			from  '../../../api.service';

@Component({
	selector: 'app-lazada-in-transit',
	templateUrl: './lazada-in-transit.component.html',
	styleUrls: ['./lazada-in-transit.component.css']
})
export class LazadaInTransitComponent implements OnInit {

	resultsLength = 0;

	data:any;

	constructor(
		private apiService: ApiService
	) { }

	ngOnInit() {

		this.index();
	}

	index(): void {
		this.apiService.lazadaPackageInTransit().subscribe(data => {

			this.data 		= data.data;

			for (var i = this.data.length - 1; i >= 0; i--) {
				this.resultsLength += this.data[i].order_deliveries_count;
			}
		});
	}
}
