import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } 					from  '../../../api.service';
import { Router} 						from '@angular/router';
import { ActivatedRoute} 				from '@angular/router';
import { ngCopy } from 'angular-6-clipboard';


@Component({
  selector: 'app-lazada-order',
  templateUrl: './lazada-order.component.html',
  styleUrls: ['./lazada-order.component.css']
})
export class LazadaOrderComponent implements OnInit {

	data:any;
	code:any;

	comments = '';
	commentsBreach = '';

    constructor(
    	private route: ActivatedRoute,
  		private apiService: ApiService,
		private router: Router
	) { }


	ngOnInit() {

		window.scroll(0,0);
		this.code = this.route.snapshot.paramMap.get('code');
	  	this.index();
	}

	index(): void {

		this.apiService.lazadaPackageDetails(this.code).subscribe(data => {

			this.data 		= data.data;

			if(!data.data) {
				alert(data.message);
				this.router.navigate(['/orders']);
			}

		});
	}

	// manualConfirm() {
	// 	this.apiService.customerReturnManualConfirm(this.code).subscribe(data => {

	// 		if(data.status)
	// 			this.index();

	// 		alert(data.message);

	// 	});
	// }

	revokeDriver() {
		this.apiService.customerReturnRevokeDriver(this.code).subscribe(data => {

			if(data.status)
				this.index();

			alert(data.message);

		});
	}

	packageLost(type) {

		if(type === 'warehouse')
			this.apiService.packageWarehouseLost(this.code).subscribe(data => {

				if(data.status)
					this.index();

				alert(data.message);

			});
		else if(type === 'driver')
			this.apiService.packageDriverLost(this.code).subscribe(data => {

				if(data.status)
					this.index();

				alert(data.message);

			});
	}

	packageBreach(type) {

		if(type === 'warehouse')
			this.apiService.packageWarehouseBreach(this.code).subscribe(data => {

				if(data.status)
					this.index();

				alert(data.message);

			});
		else if(type === 'driver')
			this.apiService.packageDriverBreach(this.code).subscribe(data => {

				if(data.status)
					this.index();

				alert(data.message);

			});
	}

	resendEmail() {
		this.apiService.customerReturnResendEmail(this.code).subscribe(data => {

			this.data 		= data.data;

			if(!data.data)
				this.router.navigate(['/orders']);

		});
	}

	scrapOrder() {
		this.apiService.customerReturnScrapOrder(this.code).subscribe(data => {

			if(data.status)
				this.index();
			
			alert(data.message);
		});
	}

	comment() {

		let param = {
			'code'		: this.code,
			'comments'	: this.comments
		}

		this.apiService.lazadaPackageComment(param).subscribe(data => {

			if(data.status) {
				this.comments = '';
				this.index();
			}
			else
				alert(data.message);
		});
	}
	commentBreach() {

		let param = {
			'code'		: this.code,
			'comments'	: this.commentsBreach
		}

		this.apiService.lazadaPackageCommentBreach(param).subscribe(data => {

			if(data.status) {
				this.commentsBreach = '';
				this.index();
			}
			else
				alert(data.message);
		});
	}

	transform(value:string): string {
	  let first = value.substr(0,1).toUpperCase();
	  return first + value.substr(1); 
	}

	openTN(tn) {

		this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
   		this.router.navigate(['/packages/'+tn]));
	}

	copyForShare() {

        ngCopy('URGENT DELIVERY - ' + this.data.trackingNumber + '\n\n' +
        	'From Hub - ' 		+ this.getLastStatus('fromhub') + '\n' +
        	'To Hub - ' 		+ this.getLastStatus('tohub') + '\n' +
        	'Current Location - ' 	+ this.getLastStatus('currentHub') + '\n' +
        	'Customer Name - ' 		+ this.data.customer.name + '\n' +
        	'Customer Contact - ' 	+ this.data.customer.mobile + '\n' +
        	'Customer Address - ' 	+ this.data.customer_address.details + '\n\n' +
        	'Last  Status - ' 		+ this.getLastStatus('status') + '\n' +
        	'Status Timestamp - ' 	+ this.getLastStatus('timestamp') + '\n' +
        	'Driver Name - ' 		+ this.getLastStatus('driver') + '\n' +
        	'Driver Phone - ' 		+ this.getLastStatus('phone')
        	);
        alert('Details copied');
    }

    getLastStatus(type) {
    	let lastDelivery = this.data.timeline[this.data.timeline.length - 1];
    	let status = lastDelivery['statuses'][lastDelivery['statuses'].length - 1];

    	if(type === 'status')
    		return status['status']['status_description'] || '';
    	else if((type === 'driver'))
    		return lastDelivery['delivery']['staff'] ? lastDelivery['delivery']['staff']['full_name']: '';
    	else if((type === 'phone'))
    		return lastDelivery['delivery']['staff'] ? lastDelivery['delivery']['staff']['contact_number'] : '';
    	else if((type === 'fromhub'))
    		return lastDelivery['delivery']['from_hub_location']['name'] || '';
    	else if((type === 'tohub'))
    		return lastDelivery['delivery']['to_hub_location']['name'] || '';
    	else if((type === 'currentHub'))
    		return this.data['order_updates']['last_hub_location']['name'] || '';
    	else if((type === 'timestamp'))
    		return status['created_at'];
    }

	reopenOrder() {
		this.apiService.customerReturnReopenOrder(this.code).subscribe(data => {

			if(data.status)
				this.index();
			
			alert(data.message);
		});
	}

	cancelTrip(id) {

		this.apiService.customerReturnCancelTrip(id).subscribe(data => {

			if(!data.status)
				alert(data.message);
			else
				this.index();
		});
	}
}
