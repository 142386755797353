/// <reference types="google.maps" />
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ApiService } 					from  '../../api.service';
import { Router} 						from '@angular/router';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { ActivatedRoute}         from '@angular/router';
import { DatePipe } from '@angular/common'
// import {} from 'google.maps';

// declare var google: any;
// declare var map: any;

@Component({
  selector: 'app-b2b-job',
  templateUrl: './b2b-job.component.html',
  styleUrls: ['./b2b-job.component.css'],
  providers: [DatePipe]
})
export class B2bJobComponent implements OnInit {
	
	options: any;
	
	form: FormGroup;
	addForm: FormGroup;
	
	submitted = false;
	addSubmitted = false;

	addresses = []
	company:any
  jobRef: any;
  today:any;

	// map: google.maps.Map;

	autoCompleteOptions = {
	    fields: ["address_components", "geometry", "name", "formatted_address"],
	    // types: ["address"],
	    componentRestrictions: { country: 'MY' }
	  }
  constructor(private apiService: ApiService,
		private router: Router,
		private fb: FormBuilder,
    private route: ActivatedRoute,
    private datepipe: DatePipe
	) { }

  get f() { return this.form.controls; }
  get af() { return this.addForm.controls; }

  ngOnInit() {
    this.today =this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    if(this.route.snapshot.paramMap.get('ref')) {

      this.jobRef = this.route.snapshot.paramMap.get('ref');
      
    }

    initMap();

  	this.form = this.fb.group({
        client_id:            ['', [Validators.required]],
        vendor_id: 		        ['', [Validators.required]],
        staff_id: 		        ['', [Validators.required]],
        transport_id: 	      ['', [Validators.required]],
        start_date: 		      ['', [Validators.required]],
        reference:            ['', [Validators.required]],
        bill_amount: 	        ['', [Validators.required]],
        bill_amount_extra:    ['', []],
        bill_amount_remarks:  ['', []],
        vendor_cost: 	        ['', [Validators.required]],
        vendor_cost_extra:    ['', []],
        vendor_cost_remarks:  ['', []],
        manpower_cost: 	      [0, [Validators.required]],
        addresses:            [this.addresses, []],
    });

    this.addForm = this.fb.group({
        full_address: ['', [Validators.required]],
		    address_1: ['', [Validators.required]],
        address_2: ['', [Validators.required]],
        city: ['', [Validators.required]],
        state_id: ['', [Validators.required]],
        lat: ['', [Validators.required]],
        long: ['', [Validators.required]],
        zipcode: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5)]],
        type: ['', [Validators.required]],
    });

    this.index()
  }

  index() {
  	this.apiService.businessJobOptions().subscribe(data => {

  		this.options 		= data.data;

      if(this.jobRef)
        this.get()
	  });
  }

  get() {
    
    this.apiService.businessGet(this.jobRef).subscribe(data => {


      this.jobRef        = data.data.order_number;
      this.company       = this.options.companies.find(x => x.id == data.data.vendor_id);
      this.form.patchValue(data.data);
      this.setMinStartDate()
      this.addresses     = data.data.addresses
      markers(this.addresses)
    })
  }

  setMinStartDate() {

     if(this.form.controls['start_date'].value < this.today)
       this.form.controls['start_date'].setValue(this.today)
  }

  create() {
  	this.submitted = true;

    if(this.form.valid) {
      this.apiService.businessCreate(this.form.getRawValue()).subscribe(data => {

          if (data.status) {
            this.submitted = false;
            this.form.reset();
            this.addresses = [];
            markers(this.addresses)
          }
          
          alert(data.message);
        });
    }
  }

  getFormValidationErrors() {
  Object.keys(this.form.controls).forEach(key => {
    const controlErrors: ValidationErrors = this.form.get(key).errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach(keyError => {
       console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
      });
    }
  });
}

  fillInAddress(place) {  // optional parameter
    	this.addForm.controls['full_address'].setValue(place.name + ', ' + place.formatted_address)
    	this.addForm.controls['address_1'].setValue(this.getAddressComp(place, 'route'))
        this.addForm.controls['address_2'].setValue(this.getAddressComp(place, 'sublocality_level_1'))
        this.addForm.controls['city'].setValue(this.getAddressComp(place, 'locality'))
        
        this.addForm.controls['zipcode'].setValue(this.getAddressComp(place, 'postal_code'))
        this.addForm.controls['lat'].setValue(place.geometry.location.lat())
        this.addForm.controls['long'].setValue(place.geometry.location.lng())
        this.addForm.controls['state_id'].setValue(this.getStateIdFromName(this.getAddressComp(place, 'administrative_area_level_1')))
	}

  getStateIdFromName(name) {

  	let state = this.options.states.find(x => x.name == name);
  	if(state)
  		return state.id;
  	return null;
  }

  getAddressComp = function (place, type) {
  	const addressNameFormat = {
      'route'						: 'long_name',
      'sublocality_level_1'			: 'long_name',
      'locality'					: 'long_name',
      'administrative_area_level_1'	: 'short_name',
      'country'						: 'long_name',
      'postal_code'					: 'short_name',
    };
      for (const component of place.address_components) {
        if (component.types[0] === type) {
          return component[addressNameFormat[type]];
        }
      }
      return '';
    };

	handleAddressChange(place:any) {
	    this.fillInAddress(place);
	    this.addAddress()
	}

	addAddress() {	
		this.addSubmitted = true;

		if(this.addForm.valid) {
			this.addresses.push(this.addForm.value)
			this.addForm.reset();
			this.addSubmitted = false;
			markers(this.addresses)
		}
	}

	removeAddress(i) {
    if (i !== -1) {
		  this.addresses.splice(i,1);
		  markers([])
      markers(this.addresses)
    }

	}

	onCompanyChange(event) {
        // this.staff = null;
        this.form.controls['staff_id'].setValue('');
        this.form.controls['transport_id'].setValue('');
        
        this.company 	= this.options.companies.find(x => x.id == event.target.value);
        // this.transports = this.options.companies.find(x => x.id == event.target.value);
    }



}
function initMap(): void {


  let map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
    center: { lat: 3.1382033262759954, lng: 101.52128863331107 },
    zoom: 12,
    mapTypeControl: false,
  });

  const marker = new google.maps.Marker({
    position: { lat: 3.1382033262759954, lng: 101.52128863331107 },
    map: map,
  });

  const infoWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: false,
    });

   marker.addListener("click", () => {
    infoWindow.setContent('BBC Sortation Centre, Lot 7430, Jalan Ciku, U9 Shah Alam, Selangor.');
    infoWindow.open(map, marker);
  });
  // markers();

  

  // Create an array of alphabetical characters used to label the markers.

  // Add some markers to the map.
  
}

function markers(addresses) {

  let map = new google.maps.Map(document.getElementById("map") as HTMLElement);

	const infoWindow = new google.maps.InfoWindow({
	    content: "",
	    disableAutoPan: false,
	  });
  	
  	const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  	if(addresses.length > 0) {
		const markers = addresses.map((position, i) => {
		    const label = labels[i % labels.length];

		    position['lng'] = Number(position['long'])
        position['lat'] = Number(position['lat'])
		    const marker = new google.maps.Marker({
		      position,
		      label,
		    });

		    // markers can only be keyboard focusable when they have click listeners
		    // open info window when marker is clicked
		    marker.addListener("click", () => {
		      infoWindow.setContent(position.full_address);
		      infoWindow.open(map, marker);
		    });

		    return marker;
		});
		
		new MarkerClusterer({ map, markers });

		var latlngbounds = new google.maps.LatLngBounds();
		for (var i = 0; i < addresses.length; i++) {
			addresses[i]['lng'] = Number(addresses[i]['long'])
      addresses[i]['lat'] = Number(addresses[i]['lat'])
		    latlngbounds.extend(addresses[i]);
		}
		map.fitBounds(latlngbounds);
		var listener = google.maps.event.addListener(map, 'idle', function()
        {
          if(map.getZoom() > 16)
            map.setZoom(17);
          google.maps.event.removeListener(listener);
        });
  	}
  	else {
  		map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
		    center: { lat: 3.1382033262759954, lng: 101.52128863331107 },
		    zoom: 16,
		    mapTypeControl: false
		  });
  	}

  // Add a marker clusterer to manage the markers.
  


}
// const locations = [
//   { lat: -31.56391, lng: 147.154312 },
//   { lat: -33.718234, lng: 150.363181 },
//   { lat: -33.727111, lng: 150.371124 },
//   { lat: -33.848588, lng: 151.209834 },
//   { lat: -33.851702, lng: 151.216968 },
//   { lat: -34.671264, lng: 150.863657 },
//   { lat: -35.304724, lng: 148.662905 },
//   { lat: -36.817685, lng: 175.699196 },
//   { lat: -36.828611, lng: 175.790222 },
//   { lat: -37.75, lng: 145.116667 },
//   { lat: -37.759859, lng: 145.128708 },
//   { lat: -37.765015, lng: 145.133858 },
//   { lat: -37.770104, lng: 145.143299 },
//   { lat: -37.7737, lng: 145.145187 },
//   { lat: -37.774785, lng: 145.137978 },
//   { lat: -37.819616, lng: 144.968119 },
//   { lat: -38.330766, lng: 144.695692 },
//   { lat: -39.927193, lng: 175.053218 },
//   { lat: -41.330162, lng: 174.865694 },
//   { lat: -42.734358, lng: 147.439506 },
//   { lat: -42.734358, lng: 147.501315 },
//   { lat: -42.735258, lng: 147.438 },
//   { lat: -43.999792, lng: 170.463352 },
// ];
// declare global {
//   interface Window {
//     initMap: () => void;
//   }
// }
// window.initMap = initMap;
